.main_containerLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #ffffff47;
  position: absolute;
  width: 100%;
  z-index: 9999;
}

.container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .ring {
  position: relative;
  width: 150px;
  height: 150px;
  margin: -30px;
  border: 4px solid transparent;
  border-radius: 50%;
  border-top: 4px solid #f57f18;
  animation: animate 4s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container .ring::before {
  content: "";
  position: absolute;
  top: 12px;
  right: 12px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: #f57f18;
  box-shadow: 0 0 0 5px #f57f1896, 0 0 0 10px #f57f187d, 0 0 0 20px #f57f184f,
    0 0 20px #f57f1833, 0 0 50px #f57f1800;
}

.container .ring:nth-child(2) {
  animation: animate2 4s linear infinite;
  animation-delay: -1s;
  border-left: 4px solid #f57f18;
  border-top: 4px solid transparent;
}

.container .ring:nth-child(2)::before {
  content: "";
  position: absolute;
  top: initial;
  bottom: 12px;
  left: 12px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: #f57f18;
  box-shadow: 0 0 0 5px #f57f1896, 0 0 0 10px #f57f187d, 0 0 0 20px #f57f184f,
    0 0 20px #f57f1833, 0 0 50px #f57f1800;
}

@keyframes animate2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.container .ring:nth-child(3) {
  animation: animate2 4s linear infinite;
  animation-delay: -3s;
  position: absolute;
  top: -66.66px;
  border-top: 4px solid transparent;
  border-left: 4px solid #389978;
}

.container .ring:nth-child(3)::before {
  content: "";
  position: absolute;
  top: initial;
  bottom: 12px;
  left: 12px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: #389978;
  box-shadow: 0 0 0 5px #3899787d, 0 0 0 10px #3899785c, 0 0 0 20px #38997840,
    0 0 20px #3899781f, 0 0 50px #38997800;
}

.container p {
  position: absolute;
  color: black;
  font-size: 1.5em;

  bottom: -80px;
  letter-spacing: 0.15em;
}


/* Firefox old*/
@-moz-keyframes blink {
  0% {
      opacity:1;
  }
  50% {
      opacity:0;
  }
  100% {
      opacity:1;
  }
} 

@-webkit-keyframes blink {
  0% {
      opacity:1;
  }
  50% {
      opacity:0;
  }
  100% {
      opacity:1;
  }
}
/* IE */
@-ms-keyframes blink {
  0% {
      opacity:1;
  }
  50% {
      opacity:0;
  }
  100% {
      opacity:1;
  }
} 
/* Opera and prob css3 final iteration */
@keyframes blink {
  0% {
      opacity:1;
  }
  50% {
      opacity:0;
  }
  100% {
      opacity:1;
  }
} 
.blink-image {
  -moz-animation: blink normal 2s infinite ease-in-out; /* Firefox */
  -webkit-animation: blink normal 2s infinite ease-in-out; /* Webkit */
  -ms-animation: blink normal 2s infinite ease-in-out; /* IE */
  animation: blink normal 2s infinite ease-in-out; /* Opera and prob css3 final iteration */
}