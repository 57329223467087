@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
.react-slick * {
  min-height: 0;
  min-width: 0;
}

.react-slick .slick-prev, .react-slick .slick-next {
  background-color: rgb(187, 184, 184);
  border-radius: 10px;
}

.react-slick .fluid__instructions {
  margin-top: 30px;
}

.slick-dots li.slick-active button:before {
  color: #f96c26;
}

.slick-dots li button:before {
  font-size: 14px;
}

@media (min-width: 480px) {
  .react-slick .fluid__image-container{
    margin: 40px;
  }

  .react-slick .fluid__instructions {
    margin: 0 20px;
    padding-top: 20px;
  }
}