/* @import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'; */
@import "./font-icon.css";
@import "./fonts.css";
/* width */


body {
  font-size: 0.875rem;
  font-family: "Arial", sans-serif;
  overflow: overlay;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
input:focus {
  outline: none;
}
.gutter-2 {
  margin-left: -8px;
  margin-right: -8px;
}
.gutter-2 [class^="col-"] {
  padding-right: 8px;
  padding-left: 8px;
}
.main-content {
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 100%;
  font-size: 0.875rem;
  color: #000000;
  position: relative;
  overflow: auto;
  max-height: 100vh;
}
li:last-child.nav-item {
  margin-left: auto;
}

#exampleFormControlSelect2 {
  width: 90%;
}
#exampleFormControlSelect2 .css-13cymwt-control {
  border: none;
  border-radius: 10px;
}


.loader_wrapper {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.loader_sidecart {
  position: absolute;
  left: 50%;
  transform: translateY(-50%);
}

/* ================== LOGIN ============= */
.login-main {
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  background: rgb(172, 223, 197);
  background: linear-gradient(
    113deg,
    rgba(172, 223, 197, 1) 20%,
    rgba(238, 214, 181, 1) 81%
  );
  padding-bottom: 50px;
  padding-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-pop {
  text-align: center;
  padding: 15px;
  background: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  box-shadow: 0px 0px 20px #00000026;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-pop a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 155px;
  height: 60px;
}

.header-pop a img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.mob-accordiancategory ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.mob-accordiancategory ul li {
  padding: 10px 20px 10px 25px;
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  height: 50px;
}

.login-page {
  max-width: 530px;
  height: auto;
  background: #fff;
  z-index: 1;
  box-shadow: 0px 0px 60px #0000001a;
  // margin: 50px auto;
  border-radius: 10px;
  // margin-bottom: 0;
}
.login-form {
  padding: 20px;
  text-align: center;
}
.login-head {
  margin-bottom: 30px;
}
.login-head h4 {
   color: #202020;
  font-size: 30px;
}
.login-head .sub-head {
  font-size: 15px;
}
.user-input {
  position: relative;
  margin-bottom: 10px;
}
.category-banner{
  min-height: 350px;
  background-size: cover;
  background-repeat: no-repeat;
}


.verify-mobile {
  position: relative;
  width: 460px;
}
.user-input .user-main {
  width: 100%;
  margin-bottom: 15px;
}
.user-input .icn-usr {
  position: absolute;
  transform: translateY(-50%);
  right: 13px;
  top: 50%;
  color: #b5b5b5;
  cursor: pointer;
}
.user-input label {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.user-input a {
  display: flex;
  align-items: center;
  color: #f57f18;
  text-decoration: underline;
}
.user-input span {
  font-size: 14px;
}
.bttn-fill .bttn_grn {
  display: block;
  margin-top: 25px;
  width: 100% !important;
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 50px;
  // padding: 10px;
  font-size: 16px !important;
  cursor: pointer;
  max-width: 315px;
}
.bttn-fill .bttn_grn span {
  margin-right: 20px;
}
.bttn-fill p {
  margin: 0;
  margin-top: 1rem;
}
.bttn-fill .bttn_trans {
  background: #fff;
  /* color: #389978; */
  margin-right: 20px;
}
.bttn-fill button:focus {
  outline: none;
}
.login-txt {
  color: #8d8d8d;
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.login-txt a {
  color: #f57f18;
  text-decoration: underline;
}
.login-txt span {
  color: #000;
}
.verify-otp {
  height: 60px;
  width: 60px;
  margin-right: 20px;
  border-radius: 5px;
  font-size: 30px;
  border: 1px solid #9d9fa2;
  background: #fff;
  text-align: center;
}
.user-input .verify-otp:last-child {
  margin: 0;
}
.main-data {
  padding-top: 8rem;
}
.main-data .listing_main {
  display: flex;
  width: 100%;
  position: fixed;
  flex-direction: row;
  align-items: flex-start;
  justify-content: stretch;
  transform: translateZ(0);
  top: 7rem;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  transition: max-width 0.8s;
  pointer-events: none;
}
/* ==================HOME============= */
header {
  align-items: center;
  box-shadow: 0px 0px 20px #00000026;
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #fff;
  z-index: 999;
}
header nav {
  margin-left: 270px;
  width: 100%;
  margin-right: 10px;
}
header nav ul.top-nav {
  display: flex;
  padding: 0;
  list-style: none;
  // height: 20px;
  margin: 0;
}
header ul.topnav > li {
  transition: all 0.3s;
}
.header-menu {
  display: flex;
  width: 100%;
  align-items: center;
  // padding: 0 1.25rem;
  border-bottom: 1px solid #d6d6d6;
  position: relative;
}
/* .main-head { width: 100%; } */
.toggleMenu {
  display: none;
}
.whishlist-mob {
  display: none;
}
ul.top-nav > li {
  display: flex;
  transition: all 1s ease-in-out;
  border-right: 3px solid #fff;
  transition: 0.25s;
  padding: 0 10px;
  height: 2.7rem;
}
ul.top-nav > li p {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  font-size: 17px;
  text-shadow: -0.5px -0.5px 0 #fff, 0.5px -0.5px 0 #fff, -0.5px 0.5px 0 #fff,
    0.5px 0.5px 0 #fff;
  font-weight: 600;
  letter-spacing: 2px;
  // padding: 12px;
}

.user-log {
  border: 1px solid #d6d6d6;
  border-radius: 50px;
  background: #fff;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #858585;
  line-height: 1;
  height: 100%;
  position: relative;
}


.user_after_login h5 {
  font-size: 16px;
  margin-bottom: 8px;
}
.user_after_login p {
  font-size: 13px;
}

.user-log .user-icon {
  font-size: 28px;
  // margin-right: 15px;
  color: #000;
}
.user-log .user-menu {
  font-size: 12px;
  margin-right: 5px;
  color: #000;
}

.login_default {
  display: block;
  width: 120px;
   border-radius: 50px !important;
  margin-right: 10px !important;
  color: #fff !important;
  font-size: 16px !important;
  padding: 10px !important;
  text-align: center;
}
.login_default:hover {
  color: #fff;
}
.custom-menu-aftr-login li a.login_default.login_trans {
  background: #fff;
  color: #3d9b7b;
  margin-right: 0;
}
.custom-menu-aftr-login img {
  height: 17px;
}
.MuiList-root .MuiListItem-button:hover {
  background-color: transparent;
}
.header-menu button:focus {
  outline: none;
}
.logo_success {
  font-size: 80px;
  color: #f96c26;
}
.filter {
  // box-shadow: 0px 1px 5px -1px #00000045;
  display: flex;
  align-items: inherit;
}
.category {
  background: #d3d3d3;
  min-width: 240px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 0.313rem 0.938rem;
  margin: 0 10px;
  align-items: center;
  cursor: pointer;
  height: 2.9rem;
}

.category .cat_icn {
  font-size: 20px !important;
  /* color: #f96c26; */
  vertical-align: middle;
}
.category .cart span {
  margin: 0;
}
.cart {
  width: 25%;
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}
.cart-details {
  width: 75%;
  display: flex;
  justify-content: flex-start;
}

.category.cart-cnt {
  min-width: 250px;
  justify-content: center;
  width: 20%;
  max-width: 300px;
}

.form-control:focus {
  background: transparent;
  outline: none;
  border-color: #d6d6d6;
  box-shadow: none;
}

.cust_head_main .form-control:focus {
  background: transparent;
  outline: none;
  border-color: #495057;
  box-shadow: none;
}

.category.search-panel {
  // width: -webkit-fill-available;
  flex:1;
  padding: 0 20px;
}
.search-panel input {
  width: 100%;
  background: transparent;
  border: none;
  padding-left: 40px;
}
.form-group {
  width: 100%;
  margin: 0;
  position: relative;
}
.cart-cnt .cart-details ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  font-size: 16px;
}
.cart-cnt .cart-details li {
  padding: 0 10px;
}
.cart-cnt .cart-details li.brd-right {
  border-right: 1px solid #bcbcbc;
  gap: 5px;
}

.main-banner {
  padding: 20px;
}
.banner-car {
  display: flex;
}
.banner-image {
   height: 400px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  position: relative;
}

.banner-image img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  object-position: right;
  border-radius: 10px;
}

.bannerImgContainer {
  height: 400px;
  display: flex;
  align-items: center;
  justify-self: center;
}

.filter .category:first-child {
  margin-left: 0;
}

.discount-offer {
  background: #000;
  padding: 35px 20px;
}
.header-cnt {
  display: flex;
  justify-content: space-between;
  margin: 8px 30px;
}
.header-details h4 {
  font-size: 22px;
  margin: 0;
}
.header-color .header-details h4 {
  color: #fff;
}
.header-details .timer {
  margin-left: 20px;
  font-size: 16px;
  color: #6d6d6d;
  /* font-family: "Helvetica Roman", sans-serif; */
}
.header-details .timer span {
  margin-right: 12px;
  vertical-align: middle;
  font-size: 24px;
}
.show_all a {
  color: #000;
  font-size: 14px;
  padding: 5px 20px;
  border-radius: 50px;
  border: 1px solid;
  line-height: 1;
  display: inline-block;
}
.header-color .show_all a {
  color: #fff;
}
.discount-section {
  padding: 20px 0;
  display: flex;
  width: 100%;
  align-items: center;
}

.discound-details {
  color: #fff;
  padding: 10px 15px;
  background: #3a3a3a;
  border-radius: 0 0 10px 10px;
}
.discound-details p {
  font-size: 20px;
  margin: 0;
}
.discound-details span {
  font-size: 15px;
}
.discount-items {
  margin: 0 13px;
  width: 100%;
}
.discount-section .discount-items:first-child {
  margin-left: 0;
}
.discount-section .discount-items:last-child {
  margin-right: 0;
}
.items-content {
  padding: 10px 0px;
  // border-bottom: 1px solid #dcdcdc;
  /* margin: 0 20px; */
}
.items-details {
  display: flex;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  /* gap: 8px; */
  padding-right: 10px;
}
.popular-container .items-container{
  flex-basis: 11%;
  max-width: 11%;
  padding: 5px;
  box-sizing: border-box;
}
.items-container{
  flex-basis: 20%;
  max-width: 12%;
  padding: 5px;
  box-sizing: border-box;
  
}
.item-animate{
  animation-name: scale-in;
  animation-duration: .4s;
}
@keyframes scale-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.items {
  position: relative;
  transition: all 0.5s;
  border:none;
  background-color: #fff;
  height: 100%;

  padding-top: 5px;
/* flex-grow: 1; */
}

.items .item_details {
  margin: 0 10px;
  min-height: 43px;
  height: min-content;
}
.bottom-price {
  font-size: 1.1rem !important;
  font-weight: bolder !important;
}
.items-discount {
  padding: 3px 6px;
  color: #fff;
  background: #f28a2f;
  border-radius: 3px;
  position: absolute;
  text-transform: uppercase;
  font-size: 12px;
  z-index: 2;
  top: 10px;
}
.likecart {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 18px;
  width: 20px;
  z-index: 99;
  margin: 0;
  padding: 0;
  display: block;
}
.likecart a {
  color: #000;
  text-decoration: none;
  font-size: 20px;
}

.likecart a.wish_selected .heart-icon {
  font-size: 22px !important;
}
.wish_selected .heart-icon {
  height: 25px;
  position: absolute;
  top: -13px;
}
.items .imgbox {
  padding: 0;
  margin: 0;
  width: 100%;
  max-height: 150px;
  min-height: 150px;
  // height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.MuiStepLabel-iconContainer .Mui-active{
  color: #9e9e9e !important;
}
.items .imgbox img {
  padding: 0 5px 10px 5px;
  margin: 0;
  height: auto;
  width: auto;
  max-height: 100%;
  max-width: 100%;
// transform: scaleX(1.1);
}
.MuiStepLabel-label{
  font-size: 1.4rem !important;
}
.out_of_stock_items.items .imgbox img {
  filter: blur(5px);
}
.filter-blurimg {
  filter: blur(5px);
}

.items .textbox p::first-letter {
  text-transform: capitalize;
}
.items .items-type p {
  color: #808080;
  font-size: 13px;
  display: flex;
  justify-content: center;
}
.items .items-type span {
  border-left: 1px solid #808080;
  padding-left: 3px;
}

.items .price {
  color: #000000;
  width: 100%;
  font-size: 14px;
  /* position: absolute; */
  bottom: 0;

  align-items: baseline;
}
.items .price .price-container {
  margin: 0 10px;
  border-top: 1px solid #cfcfcf;
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.items .card-footer {
  padding: 0;
  background-color: #fff;
  border-top: none;
  /* margin-top: 5px; */
}
.items .card-body {
  padding: 0;
}
.items .price span {
  text-decoration: line-through;
  color: #b1b1b1;
  font-size: 12px;
  /* margin-left: 10px; */
}
.items:hover {
  /* border-radius: 10px; */
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
}
.items:hover:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.4) 20%,
    rgba(255, 255, 255, 1) 81%
  );
  z-index: 1;
  display: none;
}
.items:hover .imgbox {
  opacity: 0.5;
  cursor: pointer;
  z-index: 9;
}

.out-of-stock {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  width: auto;
  text-align: center;
}
.out-of-stock .text {
  padding: 4px 10px;
  border-radius: 15px;
  font-weight: 900;
  background: #00000030;
  font-size: 11px;
}

.brand-content {
  padding: 35px 0;
}
.brand-home-container {
  display: flex;
  justify-content: space-around;
  width: 98%;
}
.brand-home-container img {
  max-width: 170px;
  object-fit: contain;
  width: 100%;
  cursor: pointer;
  mix-blend-mode: multiply;
}
.brands-logo {
  padding: 30px 20px;
  display: flex;
  min-height: 230px;
}
.brands-logo .brand-data {
  margin: 0 10px;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  cursor: pointer;
  padding: 8px;
  width: 100%;
}
.brands-logo .brand-data img {
  max-height: 100%;
  max-width: 100%;
}
.brands-logo .brand-data:first-child {
  margin-left: 0;
}
.brands-logo .brand-data:last-child {
  margin-right: 0;
}

.cart-section-m-30 {
  margin-left: 33%;
}



.inputbox input::placeholder {
  color: #fff;
}


.items:hover .add_tocart {
  display: block;
}
.items:hover .add-count {
  display: block;
}
.add-count {
  display: none;
}
.add_tocart {
  display: none;
}
.add_tocart button {
  padding: 5px 0;
  margin: 0;
  width: 100%;
   color: #fff;
  text-align: center;
  font-size: 14px;
  text-transform: capitalize;
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}
.add_cart button {
  padding: 7px 0;
  margin: 0;
  width: 200px;
  color: #fff !important;
  text-align: center;
  font-size: 18px;
  text-transform: capitalize;
  border: 0;
  outline: 0;
  /* border-radius: 25px; */
  margin-top: 15px;
  height: 40px;
}
.textrow {
  padding: 0;
  margin: 0;
  bottom: 14px;
  left: 0;
  right: 0;
  display: flex;
}
.cart-count_box {
  display: flex;
  width: 100%;
  height: 30px;
  align-items: center;
  border-radius: 5px;
  position: relative;
  max-width: 260px;
}
.count_box {
  display: flex;
  width: 100%;
  background: #3d9b7b;
  height: 30px;
  align-items: center;
  border-radius: 5px;
  position: relative;
  /* margin-right: 5px; */
  max-width: 260px;
}

.cart-count_box .count_btn {
  padding: 0 6px;
  margin: 0;
  height: 100%;
  width: 30%;
  min-width: 24px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  border: 0;
  outline: 0;
  cursor: pointer;
  background: 0 0;
}
.count_box .count_btn {
  padding: 0 6px;
  margin: 0;
  height: 100%;
  width: 30%;
  min-width: 24px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  border: 0;
  outline: 0;
  cursor: pointer;
  background: 0 0;
}
.count_btn span {
  font-size: 12px;
}
.cart-count_box .count_no {
  height: 100%;
  width: 95%;
  min-width: 36px;
  font-size: 16px;
  color: #000;
  text-align: center;
  background: #fff;
  line-height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
}
.count_box .count_no {
  height: 100%;
  width: 40%;
  min-width: 36px;
  font-size: 16px;
  color: #000;
  text-align: center;
  background: #fff;
  /* border: 1px solid #3d9b7b; */
  line-height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
}
.count_no .MuiCircularProgress-root {
  width: 30px !important;
  height: 30px !important;
}
.case-cnt {
  border: 1px solid #3d9b7b;
  background: #f3fffb;
  border-radius: 5px;
  /* padding: 2px 10px; */
  width: 100%;
  margin-top: 5px;
  display: flex;
  justify-content: center;
}
.case-cnt span {
  display: flex;
  height: 100%;
}
.case-cnt .case-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.case-cnt label {
  margin-bottom: 0;
  margin-left: 10px;
  display: -webkit-box;
  cursor: pointer;
}

.items:hover .item_details .textbox {
  overflow: visible;
  text-overflow: clip;
  display: block;
  -webkit-line-clamp: initial;
}

.item_details .textbox {
  // display: flex;
  // justify-content: center;
  text-align: center;
  font-size: 17px;
  overflow: hidden;
}
.textbox p {
  margin: 0;
  line-height: 1.6rem;
}

.leftmenu {
  position: fixed;
  top: 0;
  bottom: 0;
  background: #fff;
  z-index: 9999;
  transition: all 0.5s;
  display: block;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
}
.leftmenu.openmenu {
  width: 75%;
  box-shadow: 3px 0 8px 1px rgba(0, 0, 0, 0.4);
  display: block;
}
.leftmenu .header-section {
  background: rgb(172, 223, 197);
  background: linear-gradient(
    113deg,
    rgba(172, 223, 197, 1) 20%,
    rgba(238, 214, 181, 1) 81%
  );
  padding: 30px 20px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.profile {
  background: #fff;
  border: 5px solid #fff;
  border-radius: 50%;
  line-height: 1;
  margin-right: 20px;
}
.profile span {
  font-size: 55px;
  color: #858585;
}
.name-section {
  width: calc(100% - 80px);
  font-size: 18px;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.name-section .close {
  transform: rotate(180deg);
}
.name-section p {
  margin: 0;
}
.name-section span {
  font-size: 16px;
}
.inner-section {
  width: 100%;
  overflow-y: overlay;
  position: relative;
  height: 75vh;
  padding: 20px;
  padding-bottom: 50px;
  margin: 0;
}
.inner-section .topmenu {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style: none;
  display: block;
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 20px;
}
.topmenu li a {
  padding: 10px;
  width: 100%;
  margin: 0;
  font-size: 14px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topmenu li .text {
  display: inline-block;
}
.topmenu li .border-arr {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #aeaeae;
}
.topmenu.menu-items li a {
  color: #787878;
}
.topmenu.menu-items {
  border: none;
}
.leftmenu_bgblack {
  padding: 0;
  margin: 0;
  position: fixed;
  display: block;
  height: 100%;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
  transition: 0.5s all;
}
.leftmenu.openmenu + .leftmenu_bgblack {
  display: block;
}

/* LISTING PAGE */

.main-data .listing_inner_data {
  display: flex;
}
.sidenav {
  position: fixed;
  top: 5rem;
  right: 0;
  bottom: 0;
  width: 320px;
  z-index: 99;
  transform: translateZ(0px);
}
.sidenav > section {
  position: relative;
  padding-top: 4.28em;
  height: 100%;
  transition: height 0.4s;
  z-index: 1;
}
.cart_side > .cart_header {
  position: absolute;
  right: 0;
  z-index: 2;
  top: 0;
  width: 100%;
  color: #fff;
}
.cart_header > .data {
  display: flex;
  padding: 0.8rem;
  height: 72px;
  background: #389978;
  align-items: center;
  justify-content: center;
}
.cart_header > .data > .number-of-items {
  position: relative;
  height: 100%;
  /* margin-top: .2rem; */
  margin-left: 0.4rem;
}
.cart_header > .data > .number-of-items > span.number {
  width: 2em;
  height: 2em;
  line-height: 1.7em;
  font-size: 0.8em;
  top: -0.5em;
  left: -0.6em;
  position: absolute;
  border: 2px solid #fff;
  border-radius: 100%;
  text-align: center;
  background: #000;
}
.cart_header > .data > .number-of-items > span {
  font-size: 3em;
  height: 100%;
}
.cart_header > .data > .price {
  flex: 1 1 auto;
  font-size: 1.625em;
  padding: 0.1em 0.5em;
  direction: ltr;
  text-align: center;
  margin-top: 12px;
}
.cart_header > .data > button {
  border: 2px solid #fff;
  border-radius: 1.5em;
  width: 10em;
  padding: 0;
  height: 3.2em;
  background: #000;
  color: #fff;
}
.cart_header > .data > button:hover {
  box-shadow: 0 0 1rem rgb(0 0 0 / 60%);
  background-color: rgba(255, 255, 255, 0.25);
}
.cart_header > .previous-order {
  width: 100%;
  background: #a1a1a1;
  height: 3.6875em;
  text-align: center;
  opacity: 1;
  border-radius: 0;
  border: 0;
  color: #fff;
}
.cart_header > .previous-order:focus {
  outline: 0;
}
.cart_header > .toggle_bttn {
  position: absolute;
  right: 1em;
  bottom: 12px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #fff;
  box-shadow: 0 0 12px 1px rgb(0 0 0 / 27%);
  border: 0;
}

.cart_header > .toggle_bttn:focus {
  outline: 0;
}
.sidenav .collapsable {
  height: 100%;
  transition: height 0.4s;
  overflow: hidden;
  box-shadow: 0 0 9px 0 rgb(0 0 0 / 36%);
  display: flex;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

.cart-listing-details {
  display: block;
  max-width: 300px;
  width: 100%;
  margin: 10px;
  background: #fff;
  box-shadow: 0px 0px 35px #00000026;
  padding: 0 15px;
  border-radius: 10px;
  max-height: 600px;
  overflow-y: auto;
  height: 100%;
  overflow: auto;
  transition: flex-grow 0.4s, flex-shrink 0.4s, height 0.4s,
    -webkit-box-flex 0.4s, -ms-flex-positive 0.4s, -webkit-flex-shrink 0.4s,
    -ms-flex-negative 0.4s;
}
.cart_items_total {
  /* position: absolute; */
  padding: 10px;
  width: 270px;
  margin: auto;
  /* bottom: 0; */
  max-height: 200px;
  height: 100%;
}
.sub_total {
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.sub_total .sub_desc {
  color: #8e8e8e;
  font-size: 16px;
}
.sub_total .sub_total_count {
  color: #000000;
  font-size: 16px;
}
.cart_sub_details {
  /* padding-bottom: 15px; */
  border-bottom: 1px solid #d6d6d6;
  /* padding-top: 15px;
  margin-bottom: 24px; */
  padding: 5px 0px;
}
.cart_sub_details:hover .count_box {
  display: inline-flex;
}
.cart_sub_details:hover .cart_details .line_count {
  display: none;
}
.cart_sub_details .cart_details .line_count .product_wrapper_cart {
  min-width: 28px;
  background: #fff;
  color: #000;
  line-height: 23px;
  font-size: 16px;
  /* border: 2px solid #3d9b7b; */
}
.cart_main_wrap {
  height: 100%;
}
// .cart-listing-details::-webkit-scrollbar {
//   width: 6px;
// }
// .cart-listing-details::-webkit-scrollbar-track {
//   border-radius: 25px;
// }
// .cart-listing-details::-webkit-scrollbar-thumb {
//   background: rgba(0, 0, 0, 0.1);
//   border-radius: 25px;
// }
.cart-listing-details .cart_sub_details:last-child {
  border: none;
}
.cart_details {
  display: flex;
  align-items: center;
}
.count_wrapper {
  width: 23%;
  position: relative;
  height: 100%;
  vertical-align: top;
  margin-right: 3%;
  min-width: 35px;
}
.cart_details .line_count {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 76px;
}
.cart_details > .line_count > .product_wrapper_cart {
  position: absolute;
  top: 0;
  height: 100%;
  text-align: center;
}
.smll_img.list_img_cart {
  display: flex;
  align-items: center;
  width: 70px;
  height: 70px;
}

.cart_inner_item .smll_img {
  display: flex;
  align-items: center;
  width: 100px;
  height: 100px;
}

.smll_img img {
  height: auto;
  display: block;
  margin: 0 auto;
  width: auto;
  max-width: 100px;
  max-height: 100%;
}
.smll_img_search {
  min-width: 50px;
}
.smll_img_search img {
  height: 50px;
  display: block;
  margin: 0 auto;
  width: auto;
  max-width: 50px;
  max-height: 100%;
  mix-blend-mode: multiply;
}
.smll_img_search .out-of-stock {
  position: absolute;
  left: 10px;
  top: 60%;
}
.cart_desc_name p {
  font-size: 13px;
   margin-left: 10px;
  margin-bottom: 10px;
}
.cart_details .count_main {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cart_details .cart-count_box {
  flex-direction: column;
  height: 76px;
  width: 40px;
}
.cart_details .count_box {
  display: none;
  flex-direction: column;
  height: 76px;
  width: 30px;
  margin-left: 8%;
}
.cart_details .count_main {
  width: 30px;
}
.cart_details .count_no {
  min-width: 28px;
}
.cart_details_count {
  display: flex;
  justify-content: space-between;
}
.cart_details_count .prod_pieces {
  font-size: 13px;
  color: #808080;
  margin-left: 10px;
  margin-right: 10px;
}
.cart_details_count .prod_cost {
  color: #000;
  font-size: 13px;
}
.note_box .note {
  padding: 7px 13px;
  background: #f3f3f3;
  border-radius: 50px;
  font-size: 13px;
  border: none;
  width: 100%;
  margin-top: 15px;
}
.note_box .note::placeholder {
  color: #8e8e8e;
}
.listing_category {
  border-right: 1px solid #d6d6d6;
  /* background: #fff; */
  z-index: 99;
  min-width: 280px !important;
  /* min-width: fit-content; */
  width: auto;
  height: 100%;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 280px !important;
  transition: max-width 0.8s;
  overflow: auto;
  pointer-events: all;
  padding-top: 18px;
  background: #f1f5f98f;
  overflow-x: hidden;
}
.collapsable {
  position: relative;
  right: 0;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
}
.close_sidenav .sidenav .collapsable {
  height: 0;
  padding-bottom: 0;
}
// .listing_category::-webkit-scrollbar {
//   width: 6px;
// }
// .listing_category::-webkit-scrollbar-track {
//   border-radius: 25px;
// }
// .listing_category::-webkit-scrollbar-thumb {
//   background: rgba(0, 0, 0, 0.4);
//   border-radius: 25px;
// }
// .listing_category::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }
.list_main_content {
  background: #f1f5f98f;
  width: 100%;
  padding-left: 18rem;
}
.list_main_content.show_sidecart {
  padding-right: 20rem;
}

.main-content.close_sidenav .sidenav {
  pointer-events: none;
}
.main-content.close_sidenav .sidenav > .cart_side > .cart_header {
  pointer-events: all;
}
.main-content.close_sidenav .sidenav > .cart_side > .collapsable {
  pointer-events: none;
}
.main-content.close_sidenav .cart_header > .toggle_bttn span {
  transform: rotate(270deg);
}
.main-content .cart_header > .toggle_bttn span {
  display: block;
  transform: rotate(90deg);
  z-index: 90;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.product_details {
  display: flex;
  align-items: center;
}

.listing_details {
  display: flex;
  justify-content: flex-start;
  padding: 8px 15px;
  padding-right: 0;
  width: 100%;
  border-bottom: 1px solid #d6d6d6;
  /* min-height: 70px; */
}
.specified_details {
  border-right: 1px solid #d6d6d6;
  display: flex;
  padding-right: 20px;
  align-items: center;
}
.specified_details ul {
  padding: 0;
  margin: 0;
  padding-right: 10px;
}
.specified_details li {
  display: inline-block;
  color: #b2b2b2;
  font-size: 14px;
  padding: 3px 0;
  cursor: pointer;
}
.specified_details li + li:before {
  content: "/";
  position: relative;
  padding: 0 8px;
}
.filter-count {
  color: #979797;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.specified_details li:last-child {
  color: #000000;
  cursor: default;
}
.specified_details li:last-child:after {
  content: "";
  padding: 0;
}
.filter_listing {
  padding-left: 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 50%;
}
.chipblock {
  display: inline-block;
  margin: 0 5px;
  padding: 8px 30px 7px 10px;
  border-radius: 50px;
  border: 1px solid #d6d6d6;
  position: relative;
  color: #000000;
  font-size: 13px;
  text-transform: capitalize;
  line-height: 1;
  margin-bottom: 0px;
  margin-top: 2px;
}
.chipblock a {
  position: absolute;
  transform: translateY(-50%);
  font-size: 12px;
  color: #f28a2f;
  top: 50%;
  right: 8px;
  text-decoration: none;
  cursor: pointer;
}
.chipblock a span {
  vertical-align: middle;
}
.list_category {
  /* display: none; */
  display: block;
  padding: 15px 20px;
  border-bottom: 1px solid #d1d1d1;
}
.list_category h4 {
  font-size: 15px;
}
.list_category .card-body {
  padding: 20px 0;
}
.list_category .card-header {
  padding: 0;
  border: none;
  border-radius: 0;
  background: #fff;
  font-size: 15px;
  color: #202020;
  display: flex;
  justify-content: space-between;
}
.list_category .card-header span {
  font-size: 10px;
  cursor: pointer;
  padding-top: 5px;
}

.list_category .list_head h4 {
  font-size: 16px;
}

.list_filter_data > ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.list_filter_data > ul .list-active {
  color: #389978;
}
.list_filter_data > ul > li.list-active::before {
  border-top: none;
  border-bottom: 5px solid #389978;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}
.list_filter_data > ul > li {
  font-size: 14px;
  color: #202020;
  margin-top: 10px;
}
.list_filter_data > ul > li span {
  font-size: 14px;
}
.list_filter_data > ul > li::before {
  content: "";
  height: 0;
  width: 0;
  border-top: 5px solid #959595;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 3px;
}
.list_filter_data ul.sub_items {
  padding-left: 30px;
  margin: 0;
  list-style: none;
}
.list_filter_data ul.sub_items li {
  padding: 5px 0;
  color: #5d5d5d;
  font-size: 14px;
}
.list_filter_data ul > li > label {
  margin: 0;
}
.list_filter_data .cat_data li::before {
  content: none;
}
.list_filter_data .cat_data li {
  padding: 0;
}
.list_filter_data .cat_data li span {
  padding-left: 0;
}
.list_filter_data span {
  padding: 0px 5px 0 0;
}
.item_listing_details {
  height: inherit;
}
.cart_checkout .bttn-fill {
  min-height: 50px;
  height: 100%;
}
.cart_checkout .bttn-fill .bttn_grn {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  padding: 7px;
}

/* DETAILS PAGE */

.details_main_desc {
  padding: 20px 30px;
}
.details_main {
  display: flex;
  width: 100%;
  position: relative;
}

.details_main .navBttns {
  z-index: 2;
}
.details_main .navBttns span {
  font-size: 16px;
  border: 2px solid #5d5d5d;
  padding: 7px 11px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-weight: 700;
  color: #5d5d5d;
}
.details_main .leftNav {
  position: absolute;
  left: 2%;
  top: 40%;
  cursor: pointer;
  transform: translateY(-50%);
}
.details_main_nav .navBttns {
  z-index: 90;
}
.details_main_nav .navBttns .leftNav {
  left: -1%;
}
.details_main_nav .navBttns .rightNav {
  right: -3%;
}
.navBttns .rightNav {
  position: absolute;
  right: 2%;
  top: 40%;
  display: block;
  cursor: pointer;
  transform: translateY(-50%) rotate(180deg);
}
.details_img_caro {
  position: relative;
  width: 50%;
  text-align: center;
  padding: 15px;
}

.details_desc_wrapper {
  padding-left: 40px;
  width: 50%;
  padding-top: 20px;
}
.details_desc_wrapper .details_head {
  display: flex;
  align-items: center;
  padding: 10px 0;
  font-size: 28px;
  padding-top: 0px;
}

.details_desc_wrapper .details_head .product_empty_brand {
  padding: 10px 0;
}
.details_desc_wrapper .details_head h3 {
  font-size: 25px;
  /* color: #343434; */
  margin: 0;
}
.details_desc_wrapper .details_type p {
  margin: 6px 0;
  margin-bottom: 5px;
  color: #808080;
  font-size: 16px;
}
.details_desc_wrapper .details_type span {
  border-left: 1px solid #808080;
  padding-left: 6px;
  margin-left: 5px;
}
.details_desc_wrapper .details_head_wrapper {
  border-bottom: 1px solid #d6d6d6;
}
.details_desc_wrapper .details_pricerow p {
  padding: 15px 0;
  margin: 0;
  font-size: 28px;
  border-bottom: 1px solid #d6d6d6;
  height: 100px;
}

.details_desc_wrapper .details_pricerow .details_pricerow_height {
  height: 100px;
}
.details_desc_wrapper .details_pricerow span {
  padding: 0 10px;
  /* font-size: 18px; */
  color: #b1b1b1;
  display: inline-block;
  text-decoration: line-through;
  /* font-family: "Helvetica Roman", sans-serif; */
}
.details_desc_wrapper .details_pricerow .price_off {
  font-size: 15px;
  color: #f28a2f;
  text-decoration: none;
  padding: 0;
}
.details_desc_wrapper .textrow {
  margin-top: 15px;
}
.details_desc_wrapper .count_box {
  margin-right: 20px;
  height: 40px;
  width: 200px;
}
.details_desc_wrapper .note_box {
  padding: 20px 0px;
}
.details_desc_wrapper .note_box .note {
  height: 40px;
  margin-top: 20px;
}
.details_sub_desc {
  display: flex;
  justify-content: flex-start;
}
.details_desc_wrapper .details_desc ul {
  padding: 20px;
  padding-left: 0px;
  color: #636363;
  font-size: 15px;
}

.details_desc_wrapper .details_desc ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.details_desc_wrapper .details_desc li {
  padding: 5px 0;
  color: #636363;
  font-size: 15px;
  display: flex;
  align-items: center;
  line-height: 1;
  min-width: 180px;
  margin: 2px;
}

.details_desc_wrapper .details_desc li::before {
  content: "";
  color: #636363;
  font-weight: bold;
  display: inline-block;
  font-size: 150%;
  margin-top: 0;
  padding-right: 12px;
  line-height: 1;
}

.details_desc_wrapper .details_desc {
  display: flex;
  align-items: center;
}
.details_main_wrapper {
  padding: 30px 0;
  border-top: 1px solid #d6d6d6;
  margin-top: 30px;
}
.details_main_details h5 {
  font-size: 16px;
  margin-bottom: 15px;
}
.details_main_details p {
  color: #5d5d5d;
  font-size: 14px;
  margin-bottom: 22px;
  letter-spacing: 0.35px;
  line-height: 25px;
}
.details_main_details p span {
  display: block;
}
.dialog_view .details_desc_wrapper {
  padding-top: 30px;
  padding-right: 65px;
}

.home-product-category {
  padding: 20px;
}
.home-product-category .newcategory,
.product-category-container .newcategory {
  position: relative;
  z-index: 99;
  // border: 1px solid #c2c2c2;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.ow-anywhere {
  overflow-wrap: anywhere;
}

.home-product-category .cat-img {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 150px;
  min-width: 130px;
  position: relative;
  padding: 8px 8px 0 8px
}
.home-product-category .cat-img img,
.product-category-container .cat-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
  border-radius: 10px;
}
.product-category-container .cat-img {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 150px;
  /* min-width: 150px; */
  width: 100%;
  padding:8px;
  position: relative;
}
.home-product-category a,
.product-category-container a {
  position: absolute;
  width: 90%;
  height: 100%;
  left: 8px;
  top: 0;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  background: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0) 20%,
  rgba(0, 0, 0, 0.5) 81%
);
  z-index: 9999;
}
.home-product-category .cat-title,
.product-category-container .cat-title {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1.5px;
  background-color: #00000057;
  padding: 5px 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-shadow: none;
  z-index: 9999;
}

.home-product-category ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0px;
}
.product-category-container {
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 0 2rem 2rem;
}
.home-product-category ul li {
  min-width: 190px;
  padding: 0 10px;
  margin-bottom: 15px;
  transition: 2s ease height;
  width: min-content;
}
.product-category-container .product-category-item {
  transition: 2s ease height;
  transition: 2s ease height;
  /* width: calc(100%/7); */
  flex-basis: 15%;
  max-width: 15%;
}
.product-category-modal{
  flex-basis: 20%;
  max-width: 20%;
  padding: 8px;
}
.dialog_view .details_desc_wrapper .count_box {
  width: 200px;
  margin: 15px 0 0;
}
.view_details button {
  display: flex;
  /* background: #f28a2f; */
  color: #fff;
  padding: 10px 30px;
  min-width: 150px;
  border-radius: 50px;
  font-size: 16px;
  margin-top: 1rem;
}

/* MODAL */
.modal_main {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 25px !important;
}
.dialog_wrapper {
  margin: 0 auto;
  width: 1000px;
  background: #fff;
  height: 650px;
  border-radius: 10px;
  overflow-y: auto;
}
.modal_quick .dialog_wrapper {
  /* height: auto; */
  max-height: 650px;
  min-height: 465px;
}
.dialog_wrapper.dialog_view {
  width: 1100px;
}
// .dialog_wrapper::-webkit-scrollbar {
//   width: 6px;
// }
// .dialog_wrapper::-webkit-scrollbar-track {
//   border-radius: 25px;
// }
// .dialog_wrapper::-webkit-scrollbar-thumb {
//   background: rgba(0, 0, 0, 0.4);
//   border-radius: 25px;
// }
.menu_head {
  display: flex;
  justify-content: space-between;
  padding: 18px 20px 18px;
  border-bottom: 1px solid #d6d6d6;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
  background: #fff;
}
.menu-head-mobile {
  background: #ffffff;
  box-shadow: 0px 0px 10px #00000026;
  padding: 20px 15px;
  height: 61px;
}
.dialog_wrapper:focus {
  outline: none;
}
.menu_title h6 {
  font-size: 20px;
  margin: 0;
  color: #000000;
}
.menu_title .specified_details {
  border: none;
}
.menu_title .specified_details li {
  font-size: 20px;
  color: #b2b2b2;
  text-decoration: underline;
}
.dialog_view .menu_title .specified_details li {
  font-size: 14px;
  text-decoration: none;
}
.menu_title .specified_details li:last-child {
  color: #000;
  cursor: default;
  text-decoration: none;
}
.menu_body {
  padding: 15px;
}
.menu_desc_wrapper {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: initial;
  bottom: 10px;
}

.menu_product .menu_desc_wrapper {
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

.sub-class-card{
  height: 130px !important;
  border: 1px solid #9f9f9f;
  display: flex;
  align-items: center;
  background-color: #f4f4f4;
  cursor: pointer;
  justify-content: center;
}

.menu_title .specified_details li {
  font-size: 14px;
  padding: 0;
  cursor: pointer;
}
/* PROFILE */
.user_details {
  padding: 30px 20px;
}
.user_main_wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}
.user_details_menu {
  min-width: 360px;
  border-radius: 5px 5px 0 0;
  box-shadow: 0px 0px 35px #00000026;
  height: 100%;
  margin-right: 20px;
}
.cust_details {
  display: flex;
  /* background: #389978; */
  border-radius: 10px 10px 0 0;
  color: #fff;
  padding: 20px;
  align-items: center;
}
.cust_details .cust_image {
  height: 60px;
  width: 60px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid #fff;
}
.cust_details .cust_user_details ul {
  margin: 0;
  padding-left: 20px;
  list-style: none;
}
.cust_user_details .cust_user_name {
  font-size: 18px;
}
.cust_main_details ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.cust_main_details li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d6d6d6;
}
.cust_main_details li:hover {
  background: #f3f3f3;
}
.cust_main_details li a {
  color: #272727;
  font-size: 15px;
  padding: 18px 20px;
  display: block;
  width: 100%;
}
.cust_main_details li span,
.cust_main_details li svg {
  font-size: 20px;
  margin-right: 20px;
  vertical-align: middle;
}
.cust_main_details li span img {
  height: 20px;
}
.cust_main_details li.active a {
  color: #f57f18;
}
.cust_main_details li.active::after {
  content: "";
  height: 0;
  width: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #f57f18;
  margin-right: 20px;
}

.user_main_wrapper .user_details_desc {
  width: 100%;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  padding: 20px 20px 40px;
  margin-bottom: 20px;
  max-height: 870px;
  overflow: auto;
}

.user_main_wrapper .user_details_desc_order {
  width: 100%;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  padding: 20px 20px 40px;
  margin-bottom: 20px;
  /* max-height: 350px; */
  overflow: auto;
}
.user_main_wrapper .user_detail_new {
  width: 50%;
}
.cust_head_main {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d6d6d6;
  display: flex;
  justify-content: space-between;
}
.cust_head_main h4 {
  font-size: 16px;
  margin: 0;
}
.cust_head_main h4 .back_order {
  color: #000;
}
.cust_head_main h4 .back_order:hover {
  text-decoration: none;
}
.cust_head_main h4 .back_order span {
  margin-right: 12px;
  color: #000;
  font-size: 15px;
}
.cust_head_main .new_add {
  color: #eb7a17;
  border-radius: 50px;
  display: inline-block;
  padding: 7px 20px;
  line-height: 1 !important;
  /* margin-bottom: -12px; */
}
.cust_head_main .new_add span {
  margin-right: 10px;
}
.user_input {
  position: relative;
  /* overflow: hidden; */
}
.user_input .cvv_input {
  position: absolute;
  font-size: 20px;
  right: 12px;
  top: 14px;
}
.user_input label.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75);
}
.user_input .user_main_input {
  width: 100%;
  margin-bottom: 20px;
}
.user_input.change_pwd input {
  background: #f3f3f3;
}
.user_input .OutlinedSelect {
  display: flex;
  width: 100%;
  padding: 4px;
}
.card_sort_wrapper .OutlinedSelect {
  display: flex;
  width: 100%;
}
.css-1okebmr-indicatorSeparator {
  display: none;
}
.css-2b097c-container {
  width: 100%;
}

.bttn_user {
  display: flex;
}
.bttn_user .bttn_grn {
  width: 200px;
}
.user_cust_wrapper {
  width: 100%;
}
.address_user {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 25px;
  margin-bottom: 20px;
}

.address_user_profile {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* border-bottom: 1px solid #d6d6d6;
  padding-bottom: 25px;
  margin-bottom: 20px; */
}
.address_user .add_more_opt {
  color: #bfbfbf;
  font-size: 20px;
  cursor: pointer;
  position: relative;
}
.address_user .add_more_opt .sub_add_details {
  position: absolute;
  width: 200px;
  height: auto;
  padding: 10px;
  border-radius: 10px;
  background: #fff;
  right: 6px;
  box-shadow: 11px 11px 15px rgb(0 0 0 / 7%);
  top: 20px;
  z-index: 10;
}
.sub_add_details ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
  color: #000;
}
.sub_add_details li {
  padding: 5px 0;
}
.cust_add.cust_payment {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d6d6d6;
}
.cust_add.cust_payment .cust_usr_name label {
  margin-bottom: 0;
  font-size: 16px;
  color: #636363;
  padding: 0 1rem;
  cursor: pointer;
}
.cust_payment .form-check {
  border: 1px solid #d6d6d6;
  padding: 12px 0px;
  border-radius: 10px;
}

.cust_payment .form-check.active {
  font-weight: 700;
}

.cust_usr_name.form-check.active {
  background-color: #7777773b;
}

.cust_payment .form-check .form-check-input {
  visibility: hidden;
}

.cust_add .cust_usr_name h5 {
  font-size: 18px;
  /* color: #000000; */
  margin-bottom: 10px;
  display: flex;
  align-items: baseline;
}
.cust_add .cust_usr_name label {
  font-size: 18px;
  color: #000000;
  margin-bottom: 10px;
  margin-left: 10px;
}
.cust_add .cust_usr_name .user_defaulit_add {
  /* color: #f57f18; */
  font-size: 12px !important;
  margin-left: 10px;
}
.cust_add .cust_address.cust_delv_add {
  margin-left: 20px;
}
.cust_add .cust_address .cust_home {
  color: #5d5d5d;
  margin-bottom: 2px;
}
.cust_add .cust_address .cust_mobile {
  /* color: #161616; */
}
.user_details_desc.order_main_wrapper {
  padding-bottom: 0;
}
.order_main {
  display: flex;
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.order_main .order_inner_details {
  display: flex;
  width: 80%;
  max-width: 100%;
}

.orderWidth {
  width: 45%;
}

.order_again {
  width: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.order_inner_details .order-details .order_head {
  font-size: 16px;
  margin-bottom: 5px;
}
.order_inner_details .order-details .details_order {
  font-size: 14px;
  color: #848484;
}
.order-details .details_order .order_status {
  color: #f57f18;
  margin-right: 10px;
  /* font-family: "Helvetica Bold", sans-serif; */
}
.order-details .details_order .order_status.order_shipped {
  color: #1878f5;
}
.order-details .details_order .order_status.order_cancelled {
  color: #f51818;
}
.order-details .details_order .order_status.order_delivered {
  color: #389978;
}
.order_link {
  display: block;
  color: #000;
}
.order_link:hover {
  color: #000;
}
.user_details_desc.order_main_wrapper .order_link:last-child .order_main {
  border: none;
  margin: 0;
}
.order_again a {
  display: inline-block;
  border: 1px solid #389978;
  color: #389978;
  font-size: 14px;
  border-radius: 50px;
  min-width: 100px;
  padding: 7px;
  line-height: 1;
  text-align: center;
}
.items-details.wishlist-details {
  padding: 0;
}
.wishlist-details .items {
  width: calc(20% - 15px);
  padding: 10px 10px 40px;
}

/* SWITCH */
.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 20px;
  margin: 0;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #8e8e8e;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50px;
}
.item-subdes-container {
  position: relative;
  top: 11%;
}
.item-subdes-container span {
  position: absolute;
  right: 0px;
  top: 0;
  padding: 2px 5px;
  border-radius: 5px;
  z-index: 99;
  font-size: 12px;
  max-width: 80%;
  // white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
}
input:checked + .slider {
  /* background-color: #389978; */
}
input:focus + .slider {
  box-shadow: 0 0 1px #389978;
}
input:checked + .slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}

/* SETTINGS */
.switch_wrapper .settings_content {
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d6d6d6;
  width: 100%;
  align-items: center;
  /* justify-content: space-between; */
}
.switch_wrapper .settings_content .setting_switch {
  margin-right: 20px;
  display: flex;
  align-items: center;
}
.switch_wrapper .settings_content .setting_details {
  font-size: 16px;
  color: #5b5b5b;
  display: inline-block;
}
.switch_wrapper .settings_content:last-child {
  margin: 0;
  border: 0;
}

/* CART */
.cart_main {
  padding: 10px 20px;
}
.cart_main .cart_wrapper {
  display: flex;
  width: 100%;
}
.cart_wrapper .cart_items {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid #d6d6d6;
  height: 70px;
}
.cart_items .cart_header {
  // width: 20%;
}
.cart_items .cart_desc {
  font-size: 20px;
  /* font-family: "Montserrat Bold", sans-serif; */
}
.cart_items .cart_desc span {
  color: #979797;
  /* font-family: "Montserrat Regular", sans-serif; */
}
.cart_items .cart_desc span:before {
  content: "-";
  margin-right: 5px;
}
.cart_items .card_sort_wrapper {
  display: inline-block;
  border: 1px solid #d5d5d5;
  border-radius: 50px;
  padding: 0 15px;
  width: 180px;
  line-height: 1;
  margin: 0 auto;
}
.card_sort_wrapper .css-g1d714-ValueContainer {
  font-size: 14px;
}
.card_sort_wrapper fieldset {
  border: none;
}
/* .cart_items .shopping_list {  } */
.cart_items .shopping_list a {
  line-height: 1;
  display: inline-block;
  color: #ffb26e;
  padding: 9px;
  font-size: 14px;
  margin: 0 auto;
  min-width: 200px;
  width: 100%;
  background: #fff7f1;
  border: 1px solid #ffb26e;
  border-radius: 50px;
  text-align: center;
}
.cart_items .total_cart_amt {
  font-size: 20px;
  color: #000;
  text-align: right;
  // width: 15%;
  display: flex;
  justify-content: end;
}
.card_sort_wrapper label {
  margin: 0;
  color: #484848;
  font-size: 13px;
}
.card_sort_wrapper select {
  border: none;
  background: transparent;
  font-size: 13px;
}
.card_sort_wrapper select:focus {
  outline: none;
}
.card_sort_wrapper .dropdown-toggle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  color: #777777;
}
.card_sort_wrapper .dropdown {
  background: #f3f3f3;
  border: 1px solid #d5d5d5;
  color: #777777;
  border-radius: 50px;
}
.cart_wrapper .cart_main_order {
  height: 100%;
  min-width: 340px;
}
.cart_wrapper .cart_order_summary {
  margin-left: 30px;
  // box-shadow: 0px 0px 35px #00000045;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}
.cart_main_order .bttn_grn.bttn_trans {
  margin-right: 0;
}
.cart_head {
  display: none;
}
.cart_main_order .bttn-fill button {
  width: inherit;
  margin-bottom: 10px;
  margin-top: 0px;
}
.order_summ h5 {
  padding: 15px 0;
  border-bottom: 1px solid #d6d6d6;
  font-size: 16px;
  /* color: #000; */
  /* font-family: "Montserrat Bold", sans-serif; */
}
.summary_details ul {
  list-style: none;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 10px;
}
.summary_details li {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  /* font-family: "Montserrat Medium", sans-serif; */
  padding: 5px 0;
}
.summary_details li .summ_det_desc {
  color: #8e8e8e;
  word-spacing: 2px;
}
.summary_details li .summ_rate {
  color: #000000;
  display: flex;
  align-items: center;
}
.summary_details li .summ_rate .dollar {
  margin-right: 5px;
}
.summary_details li .summ_rate .discount_rate {
  color: #f57f18;
}
.summary_details ul.tip_don {
  padding-top: 10px;
}
.summary_details ul.tip_don .summ_rate input {
  border: 1px solid #cfcfcf;
  width: 70px;
  display: flex;
  justify-content: flex-end;
  border-radius: 4px;
  font-size: 13px;
  padding: 3px 5px;
  text-align: right;
}
.summ_total {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  font-size: 18px;
  /* font-family: "Montserrat Bold", sans-serif; */
}
.cart_total span {
  color: #8e8e8e;
  font-size: 1.3rem;
  padding-left: 15px;
}
.cart_inner_head table {
  width: 100%;
}
.cart_inner_head td {
  border-bottom: 1px solid #d6d6d6;
  padding: 20px;
}
.cart_inner_head td.cat_item {
  text-transform: uppercase;
  /* font-family: "Helvetica Bold", sans-serif; */
  color: #202020;
}
.cart_inner_head td.sub_head_cart {
  padding: 0;
}
.cart_inner_head td.cart_quantity {
  text-align: center;
  text-transform: uppercase;
  /* font-family: "Helvetica Bold", sans-serif; */
  color: #202020;
}
.cart_inner_head td.cart_unit {
  text-align: right;
  text-transform: uppercase;
  /* font-family: "Helvetica Bold", sans-serif; */
  color: #202020;
}
.cart_inner_head td.cart_item {
  text-transform: uppercase;
  /* font-family: "Helvetica Bold", sans-serif; */
  color: #202020;
}
.cart_inner_head td.unit_price {
  text-align: right;
  font-size: 18px;
  /* width: 125px; */
}
.cart_inner_head.order_succ_detail tr:first-child td {
  background: #f3f3f3;
  border-top: #d6d6d6;
  border-bottom: #d6d6d6;
  padding: 20px 2px;
}
.cart_inner_head.order_succ_detail tr:last-child td {
  border: none;
}
.cart_inner_head.order_succ_detail td {
  padding: 7px;
}
.cart_inner_head.order_succ_detail td.case_details {
  text-align: center;
  font-size: 18px;
}
.cart_price_details .textrow {
  width: 120px;
  margin: 15px auto 0;
}
.cart_wrapper .cartdetails {
  width: 100%;
  display: block;
  margin-left: 33%;
}
.cartdetails .cart_subheader {
  background: #f3f3f3;
  /* font-family: "Montserrat Medium", sans-serif; */
  padding: 6px 20px;
}
.cart_inner_item {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.cart_description {
  margin-left: 20px;
}
.cart_price_details .count_box .count_btn {
  width: 30%;
}
.cart_price_details .count_box .count_no {
  width: 40%;
}
.cart_price_details .case {
  display: flex;
  align-items: center;
}
.cart_price_details .case label {
  margin: 0;
}
.cart_bttn {
  // display: flex;
  // margin-top: 40px;
  // margin-bottom: 18px;
  /* justify-content: space-between; */
}
.cart_bttn .empty_cart button {
   
    display: inline-block;
 
    font-size: 14px;
  
    width: 170px;
    text-align: center;
    border-radius: 50px;
}
.cart_bttn .empty_cart button:focus {
  outline: none;
}
.cart_bttn .cont_shop button {
  /* background: #f57f18; */
  color: #fff;
}
.unit_price .price_strike {
  font-size: 16px;
  color: #b1b1b1;
  margin-right: 10px;
  text-decoration: line-through;
}

/* DELIVERY ADDRESS */
.delivery_wrapper {
  width: 100%;
}
.delivery_add {
  padding: 20px;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  margin-bottom: 20px;
}
.delivery_add.deilivery_new_add {
  max-height: 355px;
  overflow: auto;
}
.delivery_wrapper .address_user:last-child {
  border: none;
  margin: 0;
  padding-bottom: 5px;
}
.address_time {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d6d6d6;
  margin-bottom: 20px;
  padding-top: 7px;
  flex-direction: column;
  height: 145px;
}
.address_time_Pickup {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d6d6d6;
  margin-bottom: 20px;
  padding-top: 15px;
  flex-direction: column;
  height: 145px;
}

.timing_drop .card_sort_wrapper select {
  color: #777777;
}

.cart_bttn.delivery_add_bttn .empty_cart button {
  width: 130px;
}

/* ORDER PAGE */
.order-success {
  width: 100%;
}
.delivery_add .order_placed {
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.order_placed .success_icon {
  font-size: 60px;
  /* color: #f57f18; */
  line-height: 1;
}
.order_placed .success_icon span {
  background: #fff7f1;
  border-radius: 50%;
}
.order_placed .order_success_details {
  margin-left: 30px;
}
.order_placed .order_success_details p {
  margin: 0;
  /* color: #5d5d5d; */
  font-size: 15px;
}
.order_placed .order_success_details p .success-msg {
  font-size: 25px;
}
.order_placed .order_success_details h5 {
  color: #389978;
  font-size: 20px;
  /* font-family: "Montserrat Bold", sans-serif; */
  margin-bottom: 10px;
}
.text-size-30{
  font-size: 30px !important;
}
.order_placed .order_success_details span {
  color: #f57f18;
}
.order-progress {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}
.order-progress .order_main_pr {
  display: inline-block;
  width: 100%;
}
.order_main_pr .order_status {
  font-size: 14px;
  text-align: center;
  margin-bottom: 15px;
  min-height: 45px;
}
.order_main_pr .order_status .sub_order_desc {
  /* font-family: "Helvetica Roman", sans-serif; */
  margin-left: 5px;
}
.order_main_pr .order_status .orer_desc {
  display: inline-block;
}
.order-progress .order_status .order_desc {
  color: #b1b1b1;
  transition: color 0.25s ease-in;
  font-size: 14px;
  text-align: center;
  /* font-family: "Helvetica Bold", sans-serif; */
}
.order-progress .order_status.order_active_s .order_desc {
  color: #4d4d4d;
}
.order_main_pr .progressBar_start {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  position: relative;
  background: #e4e4e4;
  transition: background 0.25s ease-in;
  margin: 0 auto;
}
.order_main_pr .progressBar {
  width: calc(100% - 10px);
  margin-left: 11px;
  margin-top: -8px;
  height: 3px;
}
.order_main_pr .sub_progressBar {
  background: #e4e4e4;
  width: auto;
  height: 3px;
  transition: transform 1s ease-in;
  left: 50%;
  position: relative;
}
.order_main_pr .sub_progressBar.active_sub_pb {
  background: #84cf82;
}
.order_main_pr .progressBar_start.progress_active {
  background: #84cf82;
}
.delivery_add.summ_add {
  min-height: 190px;
  height: 100%;
}
.summ_details .summ_mobile {
  display: block;
}
.summ_details h6 {
  /* color: #161616; */
  font-size: 16px;
}
.summ_details span {
  color: #5d5d5d;
  font-size: 14px;
}
.summ_details .summ_mobile {
  /* color: #161616; */
}
.cart_order_bttn .mobile_cart_order {
  display: none;
}
.cart_inner_head .cart-listing-details {
  display: none;
}
.order-det_mob {
  display: none;
}

/* SEARCH */
.search-results {
  position: absolute;
  min-width: 650px;
  max-height: 550px;
  height: 485px;
  border-radius: 5px;
  box-shadow: 0px 10px 20px #00000033;
  background: #fff;
  z-index: 99;
  left: -15px;
  top: 42px;
  overflow: hidden;
}
// .search-results::-webkit-scrollbar {
//   width: 6px;
// }
// .search-results::-webkit-scrollbar-track {
//   border-radius: 25px;
// }
// .search-results::-webkit-scrollbar-thumb {
//   background: rgba(0, 0, 0, 0.1);
//   border-radius: 25px;
// }
.search-head {
  background: #eaeaec;
  border-radius: 5px 5px 0 0;
  padding: 14px 20px;
  color: #3a3a3a;
}
.search-head h6 {
  margin: 0;
  /* font-family: "Helvetica Bold", sans-serif; */
  font-size: 14px;
}
.search_content:hover {
  background-color: rgba(202, 202, 202, 0.512);
}
.search-preview-section-bg {
  padding-top: 20px;
  background: #f1f5f9;
}
.search-btn-cart {
  background: #419b7a;
  color: white;
  padding: 11px;
  width: 50px !important;
  height: 50px !important;
  border-radius: 100%;
}
.disabled-btn{
  opacity: 0.6;
    cursor: not-allowed;
}
.search-preview-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.search-popover-itmname {
  font-size: 20px;
  font-weight: 900;
  margin-top: 10px;
  white-space: break-spaces;
}
.category-group-setion {
  padding: 0 15px;
}
.category-group-setion ul {
  list-style: none;
  padding-left: 15px;
}
.category-group-setion ul li {
  background-color: #e4e4e485;
  position: relative;
  margin: 8px 0;
  padding: 2px 5px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* box-shadow: -2px 2px 15px #a9a9a9d1 inset; */
}
.category-group-setion ul li.activeItem {
  box-shadow: none !important;
}

/* .category-group-setion ul li:hover{
  background-color: #f57f1829;
} */

.category-group-setion ul li .item-count {
  position: absolute;
  right: 6px;

  color: #f57f18;
  display: flex;
  align-items: center;
  gap: 6px;
}
.category-group-setion h5 {
  padding: 0.6rem;
  font-weight: 700;
  letter-spacing: 1px;
  display: flex;
  margin-bottom: auto;
  font-size: 1.2rem;
}
.category-parent-name .category-back-icon {
  transform: rotate(180deg);
  padding: 0;
  cursor: pointer;
  margin-top: auto;
}
.category-parent-name {
  box-shadow: -1px 1px 20px #a9a9a9a8;
  display: flex;
  border-radius: 11px;
}
.filter-panel {
  list-style: none;
  padding: 0;
  max-height: 500px;
  overflow: auto;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* column-gap: 20px; */
  min-width: 290px;
}
.filter-panel-swipable {
  list-style: none;
  padding: 0;
  max-height: 500px;
  overflow: auto;
  /* min-height: 180px; */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  column-gap: 20px;
  min-width: 290px;
}
.filter-panel li {
  /* max-height: 2rem; */
}
.refine-btn {
  border-radius: 15px !important;
  padding: 0.4rem 1.4rem !important;

  transition: all 0.6s !important;
  font-size: 1.1rem !important;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
}

/* .filter-panel li:first-child{
font-weight: bolder;
} */
.search_popover_img  {
  width: 150px;
  height: 170px;
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px 0px, rgba(0, 0, 0, 0.19) 0px 3px 10px 0px;
  border-radius:15px; */
  object-fit: contain;
  mix-blend-mode: multiply;
}
.search_content {
  display: flex;
  gap: 20px;
  padding: 5px 15px;
  align-items: center;
  border-bottom: 1px solid #d6d6d6;
  min-height: 80px;
}
.search_content .likecart_search {
  width: 5%;
}
.search_content .likecart_search span {
  color: #dc3545;
  font-size: 17px;
}
.search_content .smll_img {
  width: 10%;
  position: relative;
  height: 70px;
  display: flex;
  align-items: center;
}
.search_content a {
  width: 50%;
}
.search_content .cart_description span {
  font-size: 14px;
  display: block;
  color: #343434;
  /* font-family: "Helvetica Roman", sans-serif; */
}
.search_content_btn .add_to_cart {
  width: 30%;
  text-align: right;
}

.search_content_btn .add_to_cart button {
  color: #fff;
  /* border-radius: 50px; */
  font-size: 13px;
  padding: 8px 30px;
  text-align: right;
  width: 160px;
}
.add_to_cart .cart_added {
  background: #fff;
  color: orange;
  display: inline-block;
}
.search_content .close_button span {
  color: #959595;
  font-size: 18px;
}
.search_content span {
  margin: 0;
}
.close_button {
  cursor: pointer;
  width: 3%;
}
.cart_desc {
  width: 100%;
  cursor: pointer;
}

/* Category */
.all_cat_arrow {
  height: 100%;
  width: 0;
  border-top: 6px solid #949494;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  position: absolute;
  right: 0;
  top: 15px;
  margin: 0;
}
.search-results.all-category {
  right: -15px;
  left: inherit;
}
.cat_body .list_filter_data > ul > li {
  padding: 10px 20px;
  border-bottom: 1px solid #d6d6d6;
}
.cat_foot {
  padding: 20px;
}
.bttn-fill.cat_foot {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  background: #fff;
}
.bttn-fill.cat_foot .bttn_grn {
  max-width: 80px;
  margin: 0;
  font-size: 13px;
  text-align: center;
  line-height: 1;
}
.bttn-fill.cat_foot .bttn_grn.bttn_trans {
  margin-right: 20px;
}
.success .MuiSnackbarContent-root {
  background-color: green !important;
}
.error .MuiSnackbarContent-root {
  background-color: red !important;
}
.after_login ul {
  margin: 0;
  list-style: none;
  display: flex;
  cursor: pointer;
  padding-left: 15px;
}
/* .after_login ul li:first-child:after {
  content: "|";
  padding: 0 25px;
  color: #c1c1c1;
} */
/* .after_login li a {
  color: #000;
} */
.after_login_menu {
  position: absolute;
  right: 12.5%;
  top: 83px;
  max-width: 250px;
  box-shadow: 0px 0px 20px #00000026;
  z-index: 101;
  border-radius: 5px;
  padding: 20px 15px;
  background: #fff;
  width: 100%;
}
.user_afterLogin ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.custom-menu-aftr-login {
  min-width: 250px;
  box-shadow: 0px 0px 20px #00000026;
  border-radius: 5px;
}
.custom-menu-aftr-login:before {
  content: "";
  position: absolute;
  top: -5px;
  width: 80px;
  right: 0;
  height: 6px;
  background: #389978;
}
.custom-menu-aftr-login ul {
  padding: 18px 16px;
}
.custom-menu-aftr-login ul li {
  padding: 0;
}
.custom-menu-aftr-login ul li {
  border-bottom: 1px solid #d3d3d3;
  font-size: 14px;
  color: #272727;
  padding: 15px 0;
}
.custom-menu-aftr-login li a {
  color: #272727;
}
.custom-menu-aftr-login li a.login_default {
  color: #fff;
}
.custom-menu-aftr-login li span {
  /* color: #389978; */
  font-size: 15px;
  margin-right: 14px;
}
.custom-menu-aftr-login ul li:first-child {
  padding-top: 0;
}
li .user_detailsName span {
  color: #303030;
  display: block;
}
.custom-menu-aftr-login li span.user_after_login {
  display: block;
  font-size: 16px;
  /* font-family: "Helvetica Bold", sans-serif; */
  margin-bottom: 8px;
  /* color: #303030; */
}
.custom-menu-aftr-login li span.user_email {
  display: block;
  font-size: 13px;
  /* color: #303030; */
}

.timing_drop .OutlinedSelect {
  display: flex;
  width: 100%;
  min-width: 192px !important;
  border: 1px solid #ddd;
}
.cart_sort .card_sort_wrapper {
  display: flex;
}
.cart_sort .card_sort_wrapper .MuiOutlinedInput-input {
  padding: 8.5px 14px;
  border: 1px solid #ddd;
  margin-right: 5px;
}

.items .addNoteSed a {
  font-size: 14px;
}

.addNoteSed img {
  height: 25px;
}

.items .addNoteSed .content {
  min-width: 230px;
}

// .addNoteSed .content::-webkit-scrollbar {
//   width: 6px;
// }
// .addNoteSed .content::-webkit-scrollbar-track {
//   border-radius: 25px;
// }
// .addNoteSed .content::-webkit-scrollbar-thumb {
//   background: rgba(0, 0, 0, 0.1);
//   border-radius: 25px;
// }


li:nth-child(odd) .catimage {
  background-color: #816363;
}
li:nth-child(even) .catimage {
  background-color: #226cb5;
}

li:nth-child(1) .catimage {
  background-color: #816363;
}
li:nth-child(2) .catimage {
  background-color: #c3386e;
}
li:nth-child(3) .catimage {
  background-color: #5a62a8;
}
li:nth-child(4) .catimage {
  background-color: #36b4de;
}
li:nth-child(5) .catimage {
  background-color: #90b85c;
}
li:nth-child(6) .catimage {
  background-color: #226cb5;
}
li:nth-child(7) .catimage {
  background-color: #429797;
}
li:nth-child(8) .catimage {
  background-color: #5f6677;
}
li:nth-child(9) .catimage {
  background-color: #ff4444;
}
li:nth-child(10) .catimage {
  background-color: #e4af1d;
}

.searchMobile {
  position: absolute;
  width: 90vw;
  max-height: 350px;
  height: 350px;
  border-radius: 5px;
  box-shadow: 0px 10px 20px #00000033;
  background: #fff;
  z-index: 11;
  left: -100px;
  top: 65px;
  overflow: auto;
  padding: 15px;
}
.searchMobile .media {
  position: relative;
  flex-wrap: wrap;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  padding: 5px 0px;
}
.searchMobile .media img {
  width: 40px;
  height: 40px;
}
.searchMobile .media .mob-s-cart {
  background-color: #3d9b7b;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.searchMobile .media p {
  font-size: 13px;
  color: #1c1c1c;
}
.searchMobile .media h6 {
  font-size: 14px;
  color: #000;
}

.searchMobile .media .MuiButton-outlined {
  border: 0px solid rgba(0, 0, 0, 0.23);
  padding: 5px 5px;
}
/* .searchMobile .media button {
  border: none;
  background: transparent;
} */
.searchAddcard .bg,
.searchAddcard .bg:hover,
.searchMobile .media .bg {
  /* background-color: #3d9b7b; */
  width: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  color: #fff;
  padding: 5px;
}
.searchAddcard .bg2,
.searchAddcard .bg2:hover,
.searchMobile .media .bg2 {
  /* background-color: #3d9b7b; */
  width: 100%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  color: #fff;
  padding: 5px;
}
.searchAddcard {
  display: flex;
  justify-content: center;
  width: 300px;
}
.searchMobile .media .text {
  color: #000;
  font-size: 16px;
}
.searchMobile .media .MuiButtonGroup-root {
  border: 1px solid #3d9b7b;
  border-radius: 20px;
  min-width: 90px;
  height: 30px;
}

.searchMobile .media .MuiFormControlLabel-root {
  margin-left: -5px;
  margin-right: 0px;
}
.searchMobile .media label {
  margin-bottom: 0;
}

.searchMobile .media .cartBtn {
  color: #f96c26;
}

.searchMobile .media .MuiIconButton-sizeSmall {
  font-size: 14px;
}
.searchMobile .media .MuiIconButton-root {
  padding: 3px !important;
}

.searchMobile .media .MuiFormControlLabel-root {
  border: 1px solid #3d9c7b;
  border-radius: 20px;
  padding: 0px 12px;
  margin-left: 5px;
}
.searchMobile .media .MuiCheckbox-colorPrimary.Mui-checked {
  color: #3d9c7b !important;
}

.searchMobile .media .MuiTypography-root {
  margin: 0;
  color: #3d9c7b;
  font-size: 13px;
}
.searchMobile .media .MuiCheckbox-root {
  color: #3d9c7b;
}
.leftmenu-main {
  position: relative;
}
.leftmenu-main .closeBtn {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
  color: #f57f18;
  padding: 5px 10px;
  border: 1px solid #f57f18;
}
.leftmenu-main .MuiIconButton-root {
  color: #f57f18;
}
.mob-filter {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  height: 100vh;
  background: #fff;
}
.mob-cat-filter {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0px;
  background: #ffffff;
  box-shadow: 0px 0px 10px #00000026;
  text-align: center;
  z-index: 998;
}
.mob-det-check {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0px;
  background: #3d9c7b;
  box-shadow: 0px 0px 10px #00000026;
  text-align: center;
  z-index: 99;
  color: #fff;
  padding: 18px;
}

.mob-cat-filter .cat {
  padding: 18px;
  border-right: 1px solid #e1e1e1;
}
.mob-cat-filter .fil {
  padding: 18px;
}
.mob-filter .menu-head-mobile {
  background: #ffffff;
  box-shadow: 0px 0px 10px #00000026;
  padding: 20px 15px;
  height: 61px;
  position: sticky;
  top: 0;
  z-index: 99;
}

.mob-filter .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #3d9b7b;
  background-color: #fff;
  border-left: 5px solid #3d9b7b;
}
.mob-filter .filter-cat {
  background-color: #f3f3f3;
  border-right: 1px solid #d2d4d8;
  height: 90vh;
  overflow: auto;
}
.mob-filter .cat-items {
  height: 90vh;
  overflow: auto;
}
.mob-filter .nav-link {
  display: block;
  padding: 18px 0px 18px 18px;
  border-bottom: 1px solid #d2d4d8;
  border-radius: 0px !important;
  font-size: 12px;
  text-transform: uppercase;
  color: #2b2b2b;
}

.mob-filter .cat-items li {
  padding: 18px 0px 18px 18px;
}

.mob-filter .cat-items .MuiCheckbox-colorSecondary.Mui-checked {
  color: #f57f18;
}

.mob-filter .mob-filter-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0px;
  background: #ffffff;
  box-shadow: 0px 0px 10px #00000026;
  padding: 21px;
  text-align: center;
}

.mob-filter .mob-filter-footer .canc {
  color: #9e9e9e;
  font-weight: 700;
}
.mob-filter .mob-filter-footer .apply {
  color: #3d9b7b;
  font-weight: 700;
}

.mob-filter .menu-head-mobile {
  padding-bottom: 140px;
}

.mob-filter label {
  margin-bottom: 0rem;
  text-transform: uppercase;
  color: #000000;
  font-size: 14px;
}
.mob-filter .MuiIconButton-root {
  padding: 0px 12px !important;
}

.mob-delivery-address {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 999;
  padding: 0;
  margin: 0 auto;
}
.bttn_grn2 {
  height: 48px;
  border-radius: 50px !important;
}

#paymentCardContainer {
  max-height: 50px;
}

.main-head {
  min-width: 260px;
  max-width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0px;
  height: 60px;
}
.head-content{
  display: flex;
  align-items: center;
  width: 100vw;
}
.main-head .logo {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.main-head img {
  height: 90px;
  width: 180px;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  bottom: 10%;
  z-index: 50;
  left: 2%;
}

.order_main_pr .progressBar_start.progress_inactive {
  background: red !important;
}

.empty_wishlist {
  text-align: center !important;
  color: #f57f18;
}
.bttn_grn.wislist_continue_btn {
  white-space: nowrap;
}
.search-pro-name {
  white-space: nowrap;
}
@media print {
  #no-print {
    display: none;
  }
}

.MuiTypography-body1 {
  font-size: 15px;
  line-height: 1.8 !important;
}

.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover,
.MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: transparent !important;
}

.MuiTreeItem-label:hover {
  background-color: transparent !important;
}
.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: transparent !important;
}
/* .MuiTreeItem-group {
  opacity: 0.7 !important;
} */

.page-item.active .page-link {
  background-color: #389978 !important;
  border-color: #389978 !important;
  color: #fff !important;
}
.page-link {
  color: #389978 !important;
}

.pagination {
  float: right;
}

.cart_sub_details:hover .note_box {
  display: block;
}

.item_details .casetext {
  font-size: 14px;
  text-align: right;
  float: right;
  /* border: 2px solid #f28a2f;
    color: #f28a2f; */
  font-weight: 900;
  padding: 2px 5px;
  border-radius: 6px;
}
.likecart .casetext {
  font-size: 14px;
  text-align: right;
  float: right;
  /* border: 2px solid #f28a2f; */
  /* color: #f28a2f; */
  font-weight: 900;
  padding: 2px 5px;
  border-radius: 6px;
  max-height: 30px;
}
.likecart .casetext p {
  display: flex;
  align-items: center;
  gap: 5px;
}
.item_details .casetext p {
  margin-bottom: 0;
  display: inline-table;
  color: #f28a2f;
}

.item_details .casetext p .cat_icn {
  margin-bottom: 0;
  color: #f28a2f;
  font-weight: 900;
  margin-right: 5px;
}
.emptyListing {
  text-align: center !important;
  color: #f57f18;
}

.details_head_wrapper .details_type {
  display: flex;
  height: 40px;
}
.details_head_wrapper .add_cart {
  max-width: 300px;
  margin-top: 15px;
}

.details_head_wrapper .details_type .case {
  font-size: 14px;
  text-align: right;
  float: right;
  /* background: #f28a2f; */
  /* color: #fff; */
  padding: 2px 5px;
  border-radius: 6px;
  margin-left: 32px;
  max-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
}

.details_head_wrapper .details_type .case .cat_icn {
  /* color: #fff; */
  margin-right: 5px;
}

.details_head_wrapper .details_type .case p {
  color: #fff;
  display: flex;
  align-items: center;
}

.brand-name {
  color: #808080 !important;
}

.cart_details_count .prod_note {
  color: #000;
  font-size: 13px;
}
.count_box input {
  /* max-width: 46px; */
  text-align: center;
  border: 0px;
  max-height: 20px;
}
.sidenav .cart_side .count_box input {
  max-width: 28px;
}

.loaderImg {
  padding: 0 !important;
}

.order_again .bttn-fill .bttn_grn {
  margin-top: 0px;
}

.searchform {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.selectBox {
  width: 250px;
  border: 1px solid #f57f18;
  border-right: 0px solid #f3f3f3;
  display: flex;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.inputBox input {
  width: 100%;
  background: white;
  padding-left: 20px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

}

.searchform button {
  border: 0;
}
.inputBox {
  border: 1px solid #f57f18;
}
.css-yk16xz-control {
  border-width: 0px !important;
  border-radius: 15px !important;
  background-color: transparent !important;
}
.css-g1d714-ValueContainer {
  border-radius: 15px !important;
}
.css-1pahdxg-control {
  border-width: 0px !important;
  box-shadow: none !important;
  border-radius: 15px !important;
}
.css-1pahdxg-control:hover {
  background-color: transparent;
}

.searchform .form-control {
  border: 0;
  border-radius: 0;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  font-size: 21px;
  width: 100%;
}

.searchform .inputBox {
  border-left: none;
  border-top: none;
  display: flex !important;
  align-items: center;
}

.searchform .search-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;

  border-radius: 0px;
  color: #fff;
  font-size: 15px;
  box-shadow: none;
  max-width: 130px;
}
.search-category-option {
  max-height: 45vh;
  overflow-y: scroll;
}
.search-category-option ul {
  list-style: none;
  padding: 0;
}
.search-category-option ul li {
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.rotate-icon {
  transform: rotate(180deg);
}
.custom-category-selectBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 10px;
  cursor: pointer;
  min-width: 20%;
  margin: 3px;
  border: none;
  border-left: 1px solid #c2bcbc;
}
.search-popover-container {
  top: 17% !important;
  left: 61% !important;
}
.search-popover-container-listingP {
  top: 18% !important;
  left: 55% !important;
}
.search-category-option ul li:hover {
  background-color: #9e9e9e;
}
.searchform .search-submit .label-text {
  animation: showallName 0.4s ease-in-out;
}
.searchform .search-submit .label-icon {
  animation: showsearchIcon 0.4s ease-in-out;
}
@keyframes showsearchIcon {
  0% {
    transform: translateY(50px);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes showallName {
  0% {
    transform: translateY(-30px);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.searchform .form-control:focus {
  background: #fff;
}

.search-empty-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #f57f18;
  font-size: 30px;
}

.selectBox .form-control {
  display: flex;
  align-items: center;
}
.back-btn {
  position: absolute;
  font-size: 18px;
}

.tbl-main .cust_head_main {
  display: flex;
  align-items: center;
}
.cart_order_bttn {
  position: inherit;
}
.singleAddtocartmob {
  display: none;
}

.count_boxmob {
  display: none !important;
}
.bottom-package {
  margin-bottom: 3px;
}

@media (max-width: 2000px) {
  // ul.top-nav > li p{
  //   font-size: 13px;
  // }

  .home-product-category ul li{
  width: calc(100% / 8);
  min-width: auto;
  }

}
@media (max-width: 1800px) {
  .items {
    /* max-width: 165px; */
  }
  .product-category-container .cat-img {
   height: 120px;
  }
  .product-category-container a{
    left: 7px;
  }
}
@media (max-width: 1600px) {
  .product-category-container{
    padding: 1rem 0 1rem 1rem;
  }
  .popular-container .items-container{
    flex-basis: 50%;
    max-width: 50%;
  }
  .home-product-category ul li{
    width: calc(100% / 6);
    min-width: auto;
  }
  .filter-container {
    padding-right: 15rem !important;
  }
  .product-category-container .product-category-item{
    width: calc(100%/7);
  }
  .product-category-container .cat-img {
    /* min-width: 138px; */
    height: 130px;
  }
  .item-addtocart-container .case-box {
    bottom: 34% !important;
  }
  .left-menu-inner {
    width: 230px !important;
  }
  .main-head{
    min-width: 230px !important;
  }
  header nav{
    margin-left: 230px !important;
  }
  .category-background-img {
    padding: 10px;
  }

  .list_main_content-new {
    padding-left: 14rem !important;
  }

  .item_details .textbox {
    font-size: 15px;
  }
  .listing_category {
    /* width: 230px !important; */
    min-width: auto !important;
  }
  .list_cat_wrapper {
    width: 230px !important;
    min-width: 230px !important;
  }
  .category-group-setion h5 {
    font-size: 15px;
  }
  .list_main_content {
    padding-left: 15rem;
  }
  .items {
    /* max-width: 174px !important; */
  }
  .bottom-price {
    font-size: 13px !important;
    font-weight: bolder !important;
  }
  .bottom-package {
    font-size: 11px !important;
  }
  .popup_delivery_btn button {
    font-size: 11px !important;
    min-width: 100px !important;
    max-width: max-content;
  }
  .popup_delivery_btn button:focus {
    box-shadow: none !important;
  }
  .items .price span {
    font-size: 10px;
  }
  .main-head img {
    height: 80px;
    width: 150px;
    left: 1%;
  }
  .searchform .search-submit {
    font-size: 11px;
    width: 100px;
    min-width: 80px;
  }
  .refine-btn {
    padding: 1rem !important;
    font-size: 13px !important;
  }
  .searchform .form-control {
    font-size: 15px;
  }
  .searchform {
    height: 40px;
  }
  .search-popover-container {
    top: 17% !important;
    left: 61% !important;
  }


  .items .imgbox{
    height: 110px;
  }
  .cart-popular-product-section h3{
    margin-top: 10px;
  }
  .cart-popular-product-section{
    padding: 1rem 5px;
  }
  .cart-section-m-30 {
    margin-left: 25%;
  }
  .cart_wrapper .cartdetails {
    margin-left: 25%;
  }
}
@media (max-width: 1599px) {
  /* .items {
    width: calc(16.4% - 10px);
  } */

  /* .list_main_content .item_listing_details .items {
    width: calc(19.4% - 10px);
  } */
}

@media (max-width: 1500px) {
  /* .list_main_content .item_listing_details .items {
    width: calc(25% - 12px);
  }
  .list_main_content.show_sidecart .item_listing_details .items {

  } */

  .custom-category-selectBox span {
    font-size: 13px;
  }
  .search-popover-container {
    top: 20% !important;
    left: 55% !important;
  }
  .search-popover-container-listingP {
    top: 20% !important;
    left: 50% !important;
  }
 
  .cart_items .cart_header {
    // width: 40%;
  }
  .cart_items .total_cart_amt {
    font-size: 18px;
  }
  .cart_items .shopping_list a {
    min-width: 175px;
  }
  .cart_items .card_sort_wrapper {
    min-width: 165px;
  }
  .cart_wrapper .cart_main_order {
    min-width: 340px;
  }
  .count_box .count_no {
    min-width: 30px;
  }
  .case-cnt label {
    margin-left: 5px;
  }
}
@media (max-width: 1400px) {
  .list_main_content.show_sidecart .item_listing_details .items {
    /* width: calc(33% - 12px); */
  }
  .home-product-category ul li {
    width: calc(100% / 6);
  }
}

@media (max-width: 1366px) {
  .cart-listing-details {
    max-width: 275px;
  }

  .discound-details p {
    font-size: 18px;
  }
  .discound-details span {
    font-size: 14px;
  }

  .wishlist-details .items {
    width: calc(25% - 12px);
  }
  .cart_inner_head td.unit_price {
    font-size: 16px;
  }
  .cust_add .cust_usr_name label {
    font-size: 16px;
  }
  .card_sort_wrapper .dropdown-toggle {
    font-size: 12px;
  }
  .dialog_wrapper.dialog_view {
    width: 80%;
  }

}

@media (max-width: 1280px) {
  .list_main_content.show_sidecart .item_listing_details .items {
    width: calc(31% - 12px);
  }

  .listing_category {
    max-width: 250px;
  }
  .cart-listing-details {
    max-width: 280px;
  }
  .specified_details li {
    font-size: 13px;
  }


}
@media (max-width: 1215px) {
  .list_main_content.show_sidecart .item_listing_details .items {
    width: calc(50% - 12px);
  }
}

@media (max-width: 1200px) {
  
  .wishlist-details .items {
    width: calc(17.3% - 20px);
  }
 
  .cart_items .cart_header {
    width: 35%;
  }
  .cart_items .shopping_list a {
    font-size: 12px;
    min-width: 160px;
  }
  .cart_wrapper .cart_main_order {
    min-width: 325px;
  }
  .address_time {
    display: block;
  }
  /* .list_main_content .item_listing_details .items {
    width: calc(33% - 12px);
  } */

  .user_main_wrapper .user_detail_new {
    width: 60%;
  }
}

@media (max-width: 1024px) {

  .home-product-category .newcategory,
  .home-product-category .cat-img {
    height: 110px;
    width: 120px;
  }
  .main-data {
    padding-top: 4rem;
  }
  .brand-home-container img {
    max-width: 120px;
  }
  .main-banner {
    padding: 20px 0px;
  }
  .newCategory-item-icon {
    text-align: -webkit-center;
  }
  .selectBox {
    display: none;
  }
  /* .home-product-category ul li {
    width: calc(100% / 7);
  } */
  .brands-logo .brand-data {
    padding: 0 8px;
    height: 60px;
  }

  .banner-image {
    height: 300px;
  }
 

  .list_main_content.show_sidecart .item_listing_details .items {
    width: calc(50% - 12px);
  }
  .category {
    min-width: 220px;
    margin: 0;
    padding: 0 !important;
  }
  .delivery-box-tab {
    margin: 5px !important;
    padding: 10px !important;
  }
  .category.cart-cnt {
    min-width: 105px;
    height: 30px;
    padding: 10px;
  }
  .filter {
    height: 50px;
  }
  .user-log .user-icon {
    font-size: 15px;
    margin-right: 10px;
  }
  .popup_delivery_btn button {
    font-size: 8px !important;
    min-width: 100px !important;
    max-width: max-content;
  }
  .popup_delivery_btn button span svg,
  .addNotes {
    font-size: 12px !important;
  }
  .main-head img {
    height: 60px;
    width: 100px;
  }
  .header-menu {
    height: 30px;
  }
  .cart-listing-details {
    max-width: 270px;
    min-height: 280px !important;
    padding: 0 5px;
    width: 190px;
  }
  .specified_details {
    // display: none;
  }
  .filter-container{
    padding: 0 !important;
  }
  .cart-cnt .cart-details li,
  .cart_main {
    padding: 3px;
  }
  .filter_listing {
    padding: 0;
    margin-top: 0px;
  }
  .filter_listing .chipblock:first-child {
    margin-left: 0;
    padding: 0;
  }
  .MuiTypography-body1,
  .new-categoryView p,
  .home-product-category .cat-title,
  .sub_total .sub_desc,
  .cart_details_count span,
  .listing-group-categoryname-section .item-count,
  .item_details .textbox,
  .addNotes svg {
    font-size: 10px !important;
    margin: 0 !important;
  }
  .left-menu-inner {
    width: 200px !important;
  }
  .list_cat_wrapper {
    width: 200px !important;
    min-width: 200px !important;
  }
  .list_main_content-new {
    padding-left: 13rem !important;
  }
  .category-background-img {
    padding: 10px;
  }
  .newCategory-item,
  .home-product-category {
    padding: 3px !important;
  }
  .listing_category {
    max-width: 245px;
  }
  .details_desc_wrapper {
    width: 100%;
    padding: 0;
  }
  .details_img_caro {
    min-width: 400px;
    width: 100%;
  }

  .details_head_wrapper .case-cnt {
    width: 40%;
  }
  /* .details_main {
    display: inline-block;
  } */
  .details_main_desc,
  .newsalee p {
    padding: 0;
  }
  .details_desc_wrapper .details_head h3 {
    font-size: 18px;
  }
  .details_desc_wrapper .details_pricerow p {
    font-size: 25px;
    height: 70px;
  }
  .fluid img {
    height: 300px !important;
  }
  .dialog_view .details_desc_wrapper .count_box {
    width: 130px;
    height: 30px;
  }
  .cart_wrapper .cart_main_order {
    min-width: 300px;
  }
  .summary_details li {
    font-size: 13px;
  }
  td .cart_price_details .textrow {
    display: inline-block;
    margin: 0 auto;
  }
  .cart_price_details .case {
    justify-content: center;
    margin-top: 5px;
  }
  .cart_items .total_cart_amt {
    font-size: 15px;
  }
  .cart_items .cart_desc {
    font-size: 17px;
  }
  .cart_items .cart_header {
    width: 25%;
  }

  .cart_main .cart_wrapper {
    display: inline-block;
  }
  .cart_wrapper .cart_order_summary {
    margin: 0;
    box-shadow: none;
    padding: 0;
  }
  .details_desc_wrapper .details_head {
    padding-top: 20px;
  }
  .list_filter_data > ul > li {
    font-size: 12px;
  }
  .smll_img.list_img_cart img {
    height: 40px;
  }
  .smll_img.list_img_cart {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .items {
    /* min-width: calc(100% / 5) !important; */
    width: 100%;
  }

  .listing_category {
    width: 200px !important;
    margin-top: 0 !important;
  }
  ul.top-nav > li p,
  .searchform .form-control,
  .sub_total .sub_total_count,
  .search-pro-name span,
  .search-pro-price span {
    font-size: 10px !important;
  }
  header nav {
    margin-left: 20%;
  }
  .cart_checkout .bttn-fill .bttn_grn {
    width: 100px !important;
    font-size: 11px !important;
  }
  .cart-cnt .cart-details li,
  .cart-cnt .cart-details li p,
  .category .cat_icn,
  .items .items-type p,
  .search_category_name span {
    font-size: 9px !important;
  }
  .cart {
    margin-right: 10px;
  }
  .details_main_nav .navBttns .rightNav {
    right: -6%;
  }
  .out-of-stock {
    left: 3%;
  }
  .bannerImgContainer {
    height: 300px;
  }
  .items .add-count {
    display: block !important;
  }
  .add-count .textrow {
    position: absolute;
    bottom: 2%;
  }

  .items .add_tocart {
    display: block !important;
    bottom: 2% !important;
    width: 100%;
    padding: 0 15px;
    position: absolute;
    left: 0;
  }
  .searchform {
    height: 30px;
  }
  .searchform .search-submit {
    font-size: 10px;
    width: 60px;
    min-width: 60px;
  }
  .sidenav {
    width: 200px;
    top: 3rem;
  }
  .cart_items_total {
    padding: 0 10px;
    width: 200px;
  }
  .bttn-fill1 button {
    font-size: 10px;
  }
  .cart_checkout {
    display: flex;
    align-items: baseline;
    height: 55px;
  }
  .count_wrapper,
  .cart_sub_details .cart_details .line_count .product_wrapper_cart,
  .count_box .count_no {
    min-width: 20px;
  }
  .cart_details .count_box {
    height: 50px;
    width: 20px;
  }
  .cart_details {
    height: 50px;
  }
  .smll_img_search img {
    height: 30px;
    width: 30px;
  }
  .list_main_content {
    padding-left: 13rem;
  }
  .listing-group-categoryname-section {
    font-size: 15px !important;
    padding: 0 !important;
  }
  .main-data .listing_main {
    top: 4.5rem;
  }
  .address_user {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  .search-results {
    height: 330px;
    top: 40px !important;
  }
  .search_content {
    min-height: 50px;
  }
  .search_popover_img {
    width: 115px;
  }
  .search-popover-itmname,
  .add_cart button {
    margin: 0;
  }
  .search_content_btn .add_to_cart button,
  .searchAddcard,
  .add_cart button {
    width: 140px;
    height: 40px;
  }
  .modal_quick .dialog_wrapper {
    height: 400px;
    min-height: 400px;
  }
  .menu_head {
    padding: 10px;
  }
}
@media (max-width: 999px) {
  .list_main_content.show_sidecart .item_listing_details .items {
    width: calc(100% - 12px);
  }
  .cms-container{
    padding: 5px !important;
  }
}

@media (max-width: 991.98px) {
  /* .menu_head {
    display: none;
    } */
    .mobi-footer-drop {
      display: block !important;
    }
    .md-footer-size {
      display: none;
    }
  .user_details_menu {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    background-color: #fff;
    z-index: 999;
    height: 100vh;
  }
  .cust_details .figure img {
    border-radius: 50%;
    width: 85px;
    height: 85px;
  }
  .figure-caption {
    font-size: 18px;
  }
  .figure-caption2 {
    font-size: 14px;
  }
  .hideMenu {
    display: none;
  }
  .showMenu .hideMenu {
    display: block;
  }
}

@media (max-width: 900px) {
  .category-banner{
    min-height: 120px;
    background-size: contain;
  }
  .home-product-category .cat-img img, .product-category-container .cat-img img{
    height: 100px;
  }
  .filter_listing{
    display: none !important;
  }
  .listing_details{
    padding: 0 0 0 10px !important;
  
  }
  .items .price{
    padding: 0 !important;
  }
  .order_placed .success_icon span {
    font-size: 5rem;
  }
  .cart_details {
    height: 100%;
    padding: 8px 5px;
    gap:6px
  }
  // .item-addtocart-container .button-addtocart {
  //   display: none !important;
  // }
  .list_main_content-new {
    padding-left: 0 !important;
  }
  .popUp .header {
    display: contents;
  }
  .delivery-box-tab-icon img {
    width: 100px;
    object-fit: cover;
  }
  
  .card-label,.item_details .textbox {
    font-size: 15px !important;
    margin-left:10px !important;
  }
  .brand-home-container img {
    min-width: 110px;
  }
  .list_cat_wrapper {
    width: 330px !important;
  }
  .list-category-filter {
    display: none;
  }
  .dropdown {
    display: none;
  }
  .searchform .search-submit {
    min-width: 70px;
  }
  .searchform .search-submit .label-text {
    font-size: 11px;
  }
  .custom-category-selectBox {
    display: none;
  }
  .details_main {
    display: block;
  }

  .items .price {
    width: 90% !important;
  }
  .user_main_wrapper .user_detail_new {
    width: 100%;
  }


  .banner-image {
    height: 250px;
  }
  
  .prods-mob {
    width: 100%;
    display: flex;
    margin-bottom: 15px;
  }
  .prods-mobmain {
    width: 100%;
    margin: 0 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .prods-mob h6 {
    font-size: 12px;
    text-align: center;
  }
  .prods-mob .prods-mobmain:first-child {
    margin-left: 0;
  }
  .prods-mob .prods-mobmain:last-child {
    margin-right: 0;
  }

  .wishlist-details .items {
    width: calc(34% - 20px);
  }

  .brands-logo .brand-data {
    width: 100%;
  }
  .brands-logo {
    overflow: hidden;
    min-height: 100px;
    padding: 0;
    margin: 15px 0;
  }
  .header-menu {
    padding: 0.5rem 0.9rem;
    height: 60px;
  }
  .toggleMenu {
    display: flex;
    padding: 0;
    margin: 0;
    width: 25px;
    height: 15px;
    align-items: center;
    cursor: pointer;
  }
  .toggleMenu .line {
    width: 100%;
    height: 2px;
    background: #464646;
    position: relative;
  }
  .toggleMenu .line:before {
    position: absolute;
    content: "";
    background: #464646;
    width: 100%;
    height: 2px;
    padding: 0;
    top: -7px;
  }
  .toggleMenu .line:after {
    position: absolute;
    content: "";
    background: #464646;
    width: 100%;
    height: 2px;
    padding: 0;
    bottom: -7px;
  }
  .main-head .logo {
    margin-left: 15px;
  }
  .main-head .logo img {
    left: auto;
    height: 100%;
    bottom: 0;
    padding: 5px 0;
  }
  header nav {
    display: none;
  }

  .filter .category:last-child {
    display: none;
  }
  .category.search-panel {
    margin: 0;
    width: 100%;
  }
  .user-log {
    display: none;
  }
  .whishlist-mob {
    display: block;
  }
  .whishlist-mob ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    position: absolute;
    right: 20px;
    top: 24px;
    align-items: center;
  }
  .whishlist-mob li img {
    height: 20px;
  }

  .css-tlfecz-indicatorContainer {
    padding: 8px 2px !important;
  }

  .inputBox input {
    padding-left: 12px;
  }
  .inputBox {
    display: inline-flex;
    align-items: center;
  }

  .whishlist-mob ul li {
    display: inline-block;
    padding: 0 10px;
    position: relative;
  }
  .credit-card-container {
    width: 100% !important;
    padding: 8px !important;
    margin: 1rem !important;
  }
  .whishlist-mob li a .count_rount,
  .count_rount {
    position: absolute;
    width: 20px;
    height: 20px;
    font-size: 11px;
    /* background: #f57f18; */
    /* font-family: "Helvetica Bold", sans-serif; */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    top: -12px;
    right: 0px;
  }
  .count_rount {
    right: 19px ;
    top: -5px !important;
  }
  .whishlist-mob ul li:last-child {
    padding-right: 0;
  }
  .whishlist-mob li span {
    font-size: 20px;
    color: #464646;
  }
  .whishlist-mob span.amount {
    font-size: 13px;
    color: #f57f18;
  }
  .footer-top {
    /* flex-wrap: wrap; */
    padding: 10px 0;
  }

  .discount-section {
    flex-wrap: wrap;
    padding-bottom: 0px;
  }
  .discount-items {
    margin: 10px;
    margin-bottom: 15px;
    flex: 1 1 45%;
  }
  .discount-section .discount-items:first-child {
    margin: 10px;
  }
  .discount-section .discount-items:last-child {
    margin: 10px;
  }

  .listing_category {
    display: none;
  }

  .menu_title .specified_details li {
    font-size: 10px;
  }
  .modal_spe_det.specified_details {
    display: inline-block;
  }
  .modal_main {
    display: block;
    top: 0 !important;
  }
  .dialog_wrapper {
    width: 100%;
    min-width: 300px;
    min-height: 300px;
  }

  .add_tocart {
    display: flex !important;
    /* display: none !important; */
    flex-direction: column-reverse;
    grid-gap: 6px;
    gap: 6px;
    position: absolute;
    width: 90%;
    bottom: 14%;
  }

  .add-count {
    display: block;
  }
  .add-count .textrow {
    flex-direction: row;
    grid-gap: 6px;
    gap: 6px;
    position: absolute;
    bottom: 2%;
    width: 90%;
    left: 6%;
  }
  .items:hover {
    /* border-radius: 0; */
    box-shadow: none;
  }
  .items:hover:before {
    content: none;
  }
  .items .item_details {
    margin: 0 !important;
    position: initial !important;
    width: 100% !important;
  }

  .details_desc_wrapper {
    padding: 15px;
  }
  .details_main_wrapper {
    padding: 15px;
    margin: 0;
  }
  .cart_main {
    padding: 0;
    /* margin-top: 1.4rem; */
  }
  .cart_wrapper .cart_items {
    display: none;
  }
  .cart_inner_head table tr:first-child td {
    display: none;
  }
  .cart_wrapper .cart_main_order {
    padding: 0;
    margin-bottom: 10rem;
  }
  .summary_details {
    padding: 0 15px;
  }
  .summ_total {
    padding: 0 15px;
    padding-top: 18px;
    padding-bottom: 1rem;
  }
  .cart_order_bttn .main_cart_bttn {
    display: none;
  }
  .cart_order_bttn {
    position: relative !important;
  }
  .cart_order_bttn .mobile_cart_order {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    box-shadow: 0px 0px 10px #00000026;
    background: #fff;
    margin-bottom: 5rem;
    align-items: center;
    position: fixed;
    bottom: -25px;
    width: 100%;
    height: 100px;
  }

  .cart_data footer {
    display: none;
  }

  .mobile_cart_order .bttn-fill {
    display: flex;
    align-items: center;
  }
  .mobile_cart_order a.bttn_grn {
    margin: 0;
    min-width: 220px;
    padding: 8px;
    font-size: 16px;
  }
  .mobile_cart_order .order_rate p {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }
  .mobile_cart_order .order_rate span {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.3px;
  }
  .order_summ h5 {
    margin: 0;
    background: #f3f3f3;
    text-transform: uppercase;
    border-top: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
    padding: 13px 15px;
    font-size: 12px;
  }
  .card_sort_wrapper .dropdown {
    width: 100%;
    margin-top: 15px;
  }
  .cust_add .cust_usr_name label {
    margin-bottom: 0;
  }
  .user_input .MuiFormLabel-root {
    font-size: 14px;
  }

  .user_main_wrapper .user_details_desc {
    padding: 0;
    border: none;
  }
  .user_details {
    padding: 15px;
    padding-bottom: 60px;
  }
  .cust_mob_head {
    /* margin: 0 -15px; */
    margin-bottom: 20px;
  }

  .bttn_user {
    position: fixed;
    width: 100%;
    bottom: 6%;
    padding: 30px 15px;
    background: #ffffff;
    box-shadow: 0px 0px 10px #00000026;
    z-index: 1;
    margin: 0 -15px;
  }
  .bttn-fill.bttn_user .bttn_grn {
    margin-top: 0;
  }
  .bttn-fill .bttn_grn {
    font-size: 16px;
    margin-top: 0 !important;
    padding: 5px 25px;
  }
  .order_main {
    display: block;
  }
  .order_main .order_inner_details {
    display: inline-block;
    width: 100%;
  }

  .cust_head_main {
    padding: 12px;
    text-transform: uppercase;
    background: #f3f3f3;
    border-top: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
    align-items: center;
  }
  .cust_head_main h4 {
    font-size: 15px;
  }

  .delivery_add {
    border: none;
    margin: 0 0px;
    padding: 0;
    z-index: 9;
  }
  .address_user {
    padding: 15px;
    margin: 0;
  }
  .address_time {
    padding: 15px;
    margin: 0;
  }
  .delivery_wrapper .address_user:last-child {
    padding: 15px;
  }
  .settings_content {
    padding: 0 15px;
  }
  .switch_wrapper {
    margin: 0 -15px;
  }
  .switch_wrapper .settings_content:last-child {
    border-bottom: 1px solid #d6d6d6;
  }
  .address_user.address_rw {
    margin: 0 -15px;
  }
  .dialog_wrapper.dialog_view {
    width: 90%;
    height: auto;
    border-radius: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 750px;
  }
  .details_img_caro img {
    width: 180px;
  }
  .delivery_add .row:last-child .cust_add.cust_payment {
    margin: 0;
    border: none;
  }
  .delivery_add .order_placed {
    display: block;
    text-align: center;
    padding-bottom: 1rem;
  }
  .order_placed .order_success_details {
    margin: 0;
  }
  .order_placed .success_icon {
    /* margin-bottom: 30px; */
  }
  .order_placed .order_success_details p {
    flex-direction: column;
  }
  .order_main_pr .order_status .sub_order_desc {
    display: block;
  }
  .order-progress .order_status .order_desc {
    font-size: 13px;
  }
  .summ_details {
    padding: 0 20px;
    margin-bottom: 13px;
  }
  .delivery_add.summ_add {
    min-height: inherit;
  }
  .cust_no_mr {
    margin: 0;
  }
  /* .cart_main_order .bttn-fill a */
  .bttn-fill.bttn_mob_order .bttn_grn {
    margin: 10px auto;
  }
  .order_mob_head {
    background: #f3f3f3;
    text-transform: uppercase;
    color: #000000;
    font-size: 14px;
    padding: 12px 15px;
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
  }
  .user_order.user_details {
    padding: 0;
  }
  .order_inner_details .order-details .details_order {
    display: flex;
    justify-content: space-between;
    margin: 15px;
    margin-bottom: 0;
    padding-bottom: 15px;
    border-bottom: 1px solid #dcdcdc;
  }
  .order_head {
    padding: 15px;
    padding-bottom: 0;
  }
  .order_again {
    width: 100%;
  }
  .order_again a {
    min-width: inherit;
    display: block;
    margin: 0 15px 20px;
    width: 100%;
  }
  .order_inner_details .order-details .order_head {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .order_inner_details .order-details .order_mob_head {
    padding: 7px;
  }
  .order_inner_details .order-details .order_mob_head:after {
    content: "";
    height: 0;
    width: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid #949494;
  }
  /* .order_inner_details .order-details:last-child .details_order { border: none; } */
  .order_inner_details .order-det_mob .details_order {
    border: none;
  }
  .order_main {
    padding: 0;
    border: none;
    margin: 0;
  }
  .order_web {
    display: none;
  }
  .order-det_mob {
    display: flex;
    justify-content: space-between;
  }

  .after_login {
    display: none;
  }

  .list_main_content.show_sidecart {
    padding: 0;
  }
  .list_main_content {
    padding: 0;
  }

  .details_main_nav .navBttns .rightNav {
    right: 1%;
    z-index: 1;
  }
  .details_main_nav .navBttns .leftNav {
    left: 1%;
    z-index: 1;
    top: -15px;
  }
  .user_wrap {
    display: none;
  }
  .bannerImgContainer {
    height: 250px;
  }
  footer {
    padding-bottom: 4.5rem;
  }
  .list_cat_wrapper div .newCategory-item:hover {
    background-color: transparent;
    color: black;
    font-weight: normal;
  }
}
@media (max-width: 768px) {
  //  .product-category-container .newcategory{
  //   min-width: 120px;
  //   width: min-content;
  //  }
  .product-category-modal{
    flex-basis: 32%;
    max-width: 32%;
    padding: 0;
  }
  .mob-margin-bottom{
    margin-bottom: 5rem !important
  }
  .margin-top-mob{
    margin-top: 15%;
  }
  .main-head{
    min-width: 100px !important;
  }
  .pickup_table_inner .timesec td{
    line-height: 16px !important;
    font-size: 13px !important;
  }
  .card-items {
    padding: 5px 0 !important;
  }
  .rowMobFav {
    width: -webkit-fill-available;
    padding: 5px;
  }
  .items .items-details {
    padding: 11px 11px 0px 0px;
  }
  
  .items .item_details {
    margin: 0 !important;
    position: initial !important;
    width: 100% !important;
  }
  .items .price {
    position: absolute !important;
    width: 100% !important;
    padding: 0 0.5rem 2rem 0.5rem;
  }
  .items .add-count {
    display: block !important;
  }
  .items .add_tocart {
    display: block !important;
    bottom: 2% !important;
    width: 100%;
    padding: 0 15px;
  }

  .home-product-category ul li {
    width: calc(100% / 3);
    margin: 5px 0;
    padding: 0 4px
  }
  .filter {
    padding: 0.2rem 0rem;
  }
  /* .main-data {
    padding-top: 8.5rem;
  } */

  .home-product-category .cat-title {
    font-size: 16px;
  }

  .cust_main_details {
    background-color: #fff;
    height: 100%;
  }

  .cart_bttn {
    display: none;
  }

  .bannerImgContainer {
    height: 160px;
  }

  .details_main .leftNav {
    top: 40%;
  }

  .navBttns .rightNav {
    top: 40%;
  }

  .user_main_wrapper .user_detail_new {
    width: 100%;
  }

  .search-panel input {
    font-size: 13px;
  }

  .selectBox .form-control {
    font-size: 13px;
  }

  .toggleMenu {
    width: 30px;
  }
  .banner-image {
    height: 160px;
  }

  .todays-deal {
    display: none;
  }
  /* .items {
    display: none;
  } */
  .search-results {
    min-width: 90vw;
    max-height: 400px;
    height: 400px;
  }

  .bttn-fill.cat_foot {
    display: flex;
    justify-content: flex-start;
    align-content: end;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .items-content {
    margin-top: 1rem;
    width: 100%;
    border-bottom:none
  }
  .items-details .items:first-child {
    display: block;
    /* margin-left: 0; */
  }
  .items-details .items:last-child {
    display: block;
    margin-right: 0;
  }
  .show_all a {
    padding: 5px 12px;
    width: max-content;
  }
  .header-details h4 {
    font-size: 18px;
  }
  .payment-mob {
    display: block;
    border-bottom: 1px dashed #707070;
  }
  .imageblock {
    display: block;
    margin-bottom: 12px;
  }


  .f_topblog {
    /* width: 100%; */
    padding: 20px 0;
  }
  .mob-foot {
    display: block;
  }

  .payment-mob .imageblock:last-child {
    margin-bottom: 20px;
  }


  .login-page {
    max-width: 345px;
  }
  .login-form {
    padding: 30px 20px;
  }
  /* .bttn-fill p { display: none; }
  .login-head span { display: none; } */
  .login-head h4 {
    font-size: 26px;
  }
  .login-head {
    margin-bottom: 25px;
  }
  .user-input label {
    font-size: 14px;
  }
  .user-input .MuiOutlinedInput-input {
    padding: 12.5px 14px;
  }
  .count_box .count_no {
    width: 70%;
    height: 35px;
  }
  .count_box .count_btn {
    width: 30%;
  }

  .details_desc_wrapper .case-cnt {
    min-width: inherit;
    max-width: inherit;
    width: 140px;
    position: inherit;
  }
  .details_desc_wrapper .count_box {
    min-width: inherit;
    max-width: inherit;
    width: 140px !important;
    margin-right: 10px;
    height: 40px !important;
  }
  .cart_inner_head table {
    display: none;
  }
  .cart_inner_head .cart-listing-details {
    display: block;
    max-width: 100%;
    margin: 0;
    height: auto;
    width: 100%;
    /* overflow-y: scroll; */
  }
  .cart_inner_head .cart_items_total {
    display: none;
  }
  .cart_head {
    display: block;
  }
  .verify-otp {
    font-size: 18px;
    height: 44px;
    margin-right: 5px;
    width: 46px;
  }
  .cart_head h5 {
    color: #000000;
    font-size: 13px;
    /* font-family: "Montserrat Medium", sans-serif; */
    background: #f3f3f3;
    padding: 7px 15px;
    border-bottom: 1px solid #d6d6d6;
    margin: 0 -15px;
  }
  .address_user {
    padding: 15px 10px;
    margin: 0;
  }
  .address_time {
    padding: 15px;
    padding-bottom: 20px;
    margin-bottom: 0;
  }
  .cust_main_mob {
    margin: 0 -15px;
    margin-bottom: 15px;
  }

  .menu_head {
    box-shadow: 0px 0px 10px #00000026;
  }

  .close_button {
    font-size: 14px;
  }
  .menu_title {
    padding-left: 10px;
  }

  .items {
    /* width: calc(50% - 24px); */
    padding: 0;
    /* margin: 0 15px 10px 15px; */
  }

  .list_main_content.show_sidecart .item_listing_details .items {
    width: calc(50% - 12px);
 
  }

  .wishlist-details .items {
    width: calc(50% - 15px);
    padding: 0;
  }
  .dialog_view .menu_head {
    box-shadow: none;
    border: none;
  }
  .dialog_view .likecart {
    top: -20px;
    right: 15px;
  }
  /* .dialog_view .close_button {
    left: 20px;
    position: absolute;
    top: 18px;
  } */
  .details_desc_wrapper .details_head h3 {
    font-size: 25px;
  }
  .dialog_view .details_desc_wrapper {
    padding: 20px;
  }
  .details_desc_wrapper .details_type p {
    font-size: 13px;
    margin-top: 0;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
  .details_desc_wrapper .details_type p span {
    border: none;
    padding: 0;
    margin: 0;
  }
  .details_desc_wrapper .details_pricerow p {
    font-size: 20px !important;
    padding: 10px 0;
    height: auto;
    line-height: 20px !important;
    border-bottom: 0;
  }

  .details_desc_wrapper .details_pricerow .price_off {
    font-size: 14px;
  }
  .details_desc_wrapper .details_desc {
    display: block;
  }
  .details_desc_wrapper .details_desc li {
    padding: 0;
    min-width: 200px;
    width: auto;
  }
  .details_desc_wrapper .details_desc ul {
    padding: 0;
    border-bottom: 0px solid #d6d6d6;
    padding-top: 6px;
  }
  .view_details a {
    display: block;
    text-align: center;
  }
  .modal_main.modal_quick {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .user_desc {
    display: none;
  }
  .user-input {
    text-align: left;
  }
  .address_user.address_rw {
    margin: 0;
  }
  .login-main {
    padding-top: 7rem;
  }


  .selectBox {
    display: none;
  }

  .order_main .order_inner_details {
    justify-content: space-between;
  }

  .orderWidth {
    width: auto;
  }
  .out-of-stock {
    top: 50%;
    z-index: 9;
  }
  .out-of-stock .text {
    font-size: 6px;
    padding: 1px;
    margin-left: 6px;
  }
  .add-count .textrow {
    flex-direction: row;
    grid-gap: 6px;
    position: absolute;
    width: auto;
    gap: 6px;
    padding: 0 15px;
    bottom: 2%;
  }
}

.newsalee {
  width: 75px;
  height: 75px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 99;
}
/* begin old */
.newsalee span {
  position: absolute;
  display: block;
  width: 145px;
  padding: 4px 0;
  background-color: #fff;
  color: #409a78;
  text-align: center;
  right: -20px;
  top: 10px;
  transform: rotate(-45deg);
  border: 2px solid #409b79;
  font-size: 13px;
  font-weight: bold;
  font-style: italic;
}
/* end */
.newsalee p {
  position: absolute;
  display: block;
  width: 145px;
  padding: 4px 0;
  background-color: #fff;
  /* color: #409a78; */
  text-align: center;
  right: -20px;
  top: 10px;
  transform: rotate(-45deg);
  /* border: 2px solid #409b79; */
  font-size: 13px !important;
  font-weight: bold;
  font-style: italic;
}

.addNotes {
  font-weight: 700 !important;
  font-size: 20px;
  display: inline-flex;
  cursor: pointer;
}

.new-cart-slide.hidden .sidenav {
  pointer-events: none;
}
.new-cart-slide.hidden.show .sidenav {
  pointer-events: unset;
}

.new-cart-slide.hidden .sidenav > section {
  padding-top: 0;
  height: 0;
}

.new-cart-slide.hidden.show .sidenav > section {
  padding-top: 0;
  height: 100%;
}

.new-cart-slide .sidenav .collapsable {
  background-color: #f3f3f3;
}

.new-cart-slide .cart-listing-details {
  max-height: 700px;
  margin-left: 10px;
  margin-top: 10px;
}

.editNotes {
  background-color: #f3f3f3;
  border-radius: 30px;
  max-width: 350px;
  min-height: 50px;
  position: relative;
  margin-bottom: 16px;
  margin-top: 16px;
}
.editNotes .editBtn {
  position: absolute;
  right: 5px;
  top: 12px;
  font-size: 16px;
  width: 27px;
  height: 27px;
  font-weight: bold;
  background-color: #c3eadd;
  color: #389978;
  border-radius: 100%;
  padding: 4px;
  border: 1px solid #389978;
  display: flex;
  align-items: center;
  justify-content: center;
}
.editNotes p {
  margin-bottom: 0;
  padding: 15px;
  max-width: 285px;
}

.popUp .MuiDialog-paper {
  padding: 24px;
}

.popUp .popbtn {
  /* background: #389978; */
  color: #fff !important;
  padding: 8px 16px;
  border-radius: 30px;
}

.popUp .popbtncancel {
  background: #fff !important;
  /* border: 1px solid #389978;
  color: #389978; */
  padding: 8px 16px;
  border-radius: 30px;
}

/* .popUp .popbtn:hover {
  text-decoration: none;
  background-color: #389978;
} */
.mob-accordiancategory {
  overflow: auto;
  max-height: 100vh;
  margin-bottom: 5rem;
}
.popUp .MuiIconButton-root {
  padding: 0px 5px;
}
.popUp .header {
  font-size: 18px;
  font-weight: 700;
}
.popUp .header span {
  color: #389978;
}

/* .popUp .MuiRadio-colorSecondary.Mui-checked {
  color: #389978;
} */


.category-content {
  position: relative;
}
.category-background-img {
  position: relative;
  background-repeat: no-repeat !important;
  background-position: right bottom !important;
  background-size: 80% !important;
  box-shadow: 1px 5px 10px -1px rgba(139, 139, 139, 0.57);
  padding: 20px 30px;
  height: 100vh;
  max-height: 85vh;
  // width: 100%;
  overflow: auto;
  width: 50vw;
}

.mobi-bottom-navigation {
  display: none;
}
.new-categoryView{
  position: relative;
  z-index: 2;
}
.new-categoryView .category-container {
  /* list-style: none;
  padding: 0; */
  width: 100%;
  color: darkslategray;
}
.new-categoryView .category-container .item {
  cursor: pointer;
  /* margin: 10px; */
  font-size: 18px;
  font-weight: 600;
  width: 50%;
  float: right;
  /* font-family: cursive; */
}
.childItem-container .c-item {
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  width: 100%;
}
.new-categoryView .category-container .item .childItem-container {
  margin-left: 25px;
}
.new-categoryView .category-container .item:hover {
  color: #f57f18;
  font-weight: bold;
}
.childItem-container .c-item:hover {
  color: #f57f18;
  font-weight: bold;
}
.new-categoryView h5:hover {
  color: #f57f18;
}

.cart-popular-product-section {
  width: 33%;
  position: fixed;
  height: 100vh;
  left: 0px;
  top: 14%;
  background: #3d9b7b99;
  padding: 1rem;
  background: linear-gradient(
    180deg,
    rgba(61, 155, 123, 1) 14%,
    rgba(255, 255, 255, 1) 90%
  );
}
.cart-popular-product-section h3 {
  color: floralwhite;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 25px;
}
.popular-container {
  display: flex;
  flex-wrap: wrap;
  /* gap: 10px; */
}
.popular-container .items {
  /* width: calc(95%/3); */
}

.new-categoryView p {
  font-weight: bolder;
  cursor: pointer;
  font-size: 18px;
  // white-space: nowrap;
}
.new-categoryView .sub-class {
  list-style: none;
  padding: 0;
  color: black;
  width: 100%;
  background-color: rgba(241, 241, 241, 0.6);
  -webkit-box-shadow: 0 0 12px 9px rgba(241, 241, 241, 0.6);
  box-shadow: 0 0 12px 9px rgba(241, 241, 241, 0.6);
}
.new-categoryView .subSub-class {
  list-style: none;
  padding: 0;
  color: black;
  width: 100%;
  // background-color: rgba(241, 241, 241, 0.6);
  // -webkit-box-shadow: 0 0 12px 9px rgba(241, 241, 241, 0.6);
  // box-shadow: 0 0 12px 9px rgba(241, 241, 241, 0.6);
}

.new-categoryView .sub-class .sub-class-item p {
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  margin: 5px;
  /* padding-bottom: 11px; */
}
.menu-wraper-inner {
  margin-top: 15px;
  width: 48vw;
}
.new-categoryView .sub-class hr {
  border-bottom: 1px solid #f57f18ab;
  width: 65%;
  margin-left: 0;
}
.subSub-class li{
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: linear;
}
.subSub-class li p {
  font-size: 14px !important;
  font-weight: normal !important;
}
.subSub-class li:hover {
  color: #f57f18;
  font-weight: bold;
  transform: translateX(8px);
}
.filter-container {
  position: fixed;
  width: 100%;
  background: white;
  z-index: 100;
  padding-right: 18rem;
  
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.new-categoryView .sub-class .sub-class-item:hover {
  color: #f57f18;
  font-weight: bold;
}
.new-categoryView h5:hover {
  color: #f57f18;
}
.details_pricerow p {
  font-size: 1.2rem;
  font-weight: bolder;
}
@media (max-width: 576px) {
  /* .home-product-category ul li {
    width: calc(100% / 2);
    margin-bottom: 8px;
  } */
  .name-section {
    gap: 1rem;
  }
  .details_img_caro {
    min-width: 350px;
    padding: 0;
    min-height: 300px;
  }
  .header-cnt {
    margin: 0 0.3rem;
  }
  .shop-btn .MuiButton-contained,
  .tbl-main .MuiButton-contained {
    font-size: 12px;
  }

  .count_box {
    max-width: 55%;
  }
  .count_box input {
    max-width: 100%;
  }
  .add_tocart button {
    max-width: 60%;
  }
  .case-cnt {
    width: 120px;
    margin-top: 5px;
    padding: 5px;
    font-size: 12px;
    height: 30px;
    margin-top: 5px;
    position: absolute;
    bottom: -5px;
    left: 5px;
    z-index: 99;
  }
  /* .count_box input {
    max-width: 24px;
  } */
  /* .newsalee span {
    font-size: 12px;
    padding: 6px 0;
    border: 3px solid #409b79;
  } */

  .items:hover {
    min-height: unset;
  }

  .item_details .casetext {
    font-size: 12px;
  }
  .item_details .casetext p {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .item_details .casetext p .cat_icn {
    margin-left: 4px;
    margin-right: 4px;
  }

  .item_details .textbox {
    font-size: 15px !important;
  }
  .items .items-type p {
    font-size: 12px !important;
    padding: 0;
    justify-content: flex-start;
  }

  .items .textbox p {
    font-size: 15px;
    font-weight: bold;
  }
  .user-input {
    width: 100%;
  }
}

.casetextincart {
  background: #f57f18;
  color: #fff;
  padding: 5px;
  margin-left: 5px;
  height: 25px;
  width: 50px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tbl-main {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000029;
  border: 1px solid #f9f9f9;
  border-radius: 5px;
}

.table-box {
  overflow-x: auto;
  border-bottom: 2px solid #e2e6ef;
  max-height: calc(100vh - 17rem);
}

.tbl-main .MuiTableCell-root {
  border-bottom: 1px solid #e2e6ef;
  padding: 15px;
  position: relative;
  white-space: nowrap;
  width: 25%;
  cursor: pointer;
}
.shoplist.tbl-main .MuiTableCell-root {
  white-space: normal;
}

.shop-btn .MuiButton-contained,
.tbl-main .MuiButton-contained {
  box-shadow: none;
  /* background-color: #389978; */
  color: #fff;
}

/* .shop-btn .MuiButton-contained:hover,
.tbl-main .MuiButton-contained:hover {
  box-shadow: none;
  background-color: #389978;
  color: #f57f18;
} */
.shop-btn button:focus,
.tbl-main button:focus {
  outline: 0;
}

.addNoteSed .MuiButtonBase-root {
  background-color: #fff7f1;
  box-shadow: none;
  /* color: #f57f18; */
  /* border: 1px solid #f57f18; */
  border-radius: 20px;
}

.addNoteSed .MuiButtonBase-root:hover {
  box-shadow: none;
  background-color: #fff7f1;
}

.shoplist .MuiTableCell-root {
  width: auto;
}
.tbl-main .MuiTableCell-root img {
  height: 50px;
}

.tbl-main .MuiTableCell-root .count_box {
  max-width: 100px;
}
.tbl-main .MuiCheckbox-colorSecondary.Mui-checked {
  /* color: #f57f18; */
}
.addNoteSed {
  position: relative;
}

.addNoteSed .content {
  position: absolute;
  top: 30px;
  left: 0px;
  max-width: 350px;
  min-width: 265px;
  min-height: 150px;
  background: #ffffff;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 8px;
  z-index: 99;
  max-height: 200px;
  overflow: auto;
}

.addNoteSed .MuiOutlinedInput-multiline {
  padding: 10px 14px;
  margin-right: 8px;
  height: 38px;
}

.addNoteSed .form-group {
  padding: 10px;
}

.addNoteSed a {
  color: #000;
  display: inline-block;
}
.addNoteSed .MuiListItem-root:hover {
  background-color: #aaa;
}

/* .addNoteSed .MuiSvgIcon-root {
  color: #f28a2f;
} */

.likecart .addNoteSed img {
  height: 22px;
}

.details_head_wrapper .case-cnt {
  height: 40px;
  margin: 15px 5px;
  width: 150px;
  cursor: pointer;
}

.casecard,.casecard-shoplist {
  display: flex;
}
.del_class {
  color: #b1b1b1;
}

.fav-list-text .MuiOutlinedInput-input {
  padding: 12px;
}

.fav-list-btn {
  height: 42px;
  box-shadow: none !important;
  margin-left: 8px !important;
  /* background-color: #389978 !important; */
  color: #fff !important;
}

.fav-list-dis {
  margin-left: 8px !important;
}

.popup .MuiFormControlLabel-root {
  margin-left: -8px !important;
}

.popup .favlist {
  height: 200px;
  overflow: auto;
}
// .popup .favlist::-webkit-scrollbar {
//   width: 6px;
// }
// .popup .favlist::-webkit-scrollbar-track {
//   border-radius: 25px;
// }
// .popup .favlist::-webkit-scrollbar-thumb {
//   background: rgba(0, 0, 0, 0.1);
//   border-radius: 25px;
// }

.searchAddcard .MuiButtonGroup-grouped {
  border: 1px solid #3d9c7b !important;
}

.searchAddcard .MuiButtonGroup-grouped:focus {
  outline: 0 !important;
}

/* .popup .MuiCheckbox-colorSecondary.Mui-checked {
  color: #f57f18;
} */

.container-section .MuiFormGroup-root {
  flex-direction: row !important;
}

.container-section #exampleFormControlSelect1 {
  border: 1px solid #cccccc;
  width: 65%;
  height: 40px;
  margin-top: 15px;
  padding-left: 2px;
}

.card_sort_wrapper #sameDayDelivery {
  border: 1px solid #cccccc;
  width: 100%;
  height: 40px;
  margin-top: 15px;
  padding-left: 2px;
  font-size: 18px;
}
.card_sort_wrapper #chooseDeliverySlot {
  border: 1px solid #cccccc;
  width: 100%;
  height: 40px;
  padding-left: 2px;
  font-size: 18px;
}

.try_with_other_word {
  text-align: center;
  margin-top: 2px;
  padding: 5px;
  color: #f47f18;
}
.try_with_other_word p {
  font-size: 1.5rem !important;
}

.showAll {
  position: sticky;
  bottom: 0;
  width: 100%;
  background: #419b7a;
  text-align: center;
  color: #fff;
  font-size: 18px;
  border-radius: 4px;
}

.showAll .MuiButton-root {
  color: #fff !important;
  padding: 6px 16px;
  font-size: 16px;
  min-width: 100%;
}

.notebtn-action {
  /* color: #3d9b7b; */
  /* border: 1px solid #3d9b7b; */
  border-radius: 100%;
  /* font-size: 25px !important; */
  padding: 1px;
  cursor: pointer;
  background-color: #3d9b7b4f;
}




.favoriteSave {
  text-align: right;
  margin-bottom: 2px;
}

.favoriteSave button {
  border-radius: 30px;
}

#hidden_tag .cart_order_summary_hidden {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}
.items .no_selected img {
  display: none;
}
.items:hover .no_selected img {
  display: block;
}
.cms-container{
  padding: 2rem 6rem;
}
.cms-header {
  height: 100px;
  display: flex;
  align-items: center;
  font-weight: bolder;
  margin-left: 1rem;
  font-size: 2rem;
}
.cms-header .title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 48px;
  line-height: 1;
  color: #fff;
  letter-spacing: 1px;
}
.cms-body {
  padding: 1.25rem;
}
.cms-body h2 {
  font-size: 1.5rem;
}

.universial_note {
  // width: inherit;
  margin-left: 30px;
  margin-top:10px;
}
.universial_note textarea {
  width: 100% !important;
  height: 69px;
  border-radius: 20px;
  padding-left: 10px;
}
.out_of_stock_content {
  opacity: 0.9;
}

.out_of_stock_content .out-of-stock .text {
  padding: 1px 2px;
  font-size: 8px;
  white-space: nowrap;
  border: 1px solid;
}

.fluid {
  max-width: 1200px;
  max-height: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  line-height: 1.3;
  font-size: 16px;
}

.fluid img {
  height: 500px !important;
  object-fit: contain;
  padding: 1.3rem;
}

iframe {
  border: 0 solid black;
  width: 100%;
  height: 60px;
  padding: 0px;
  margin-bottom: 5px;
}

.card_type {
  border: 1px solid #000;
  padding: 8px;
}

#data-form label {
  display: inline-block !important;
}

#data-form input {
  padding: 8px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

#card-data-error {
  color: red;
}

.paymentGet {
  display: flex;
}
.paymentGet .form-control {
  height: 48px;
}
.tbl-main.show-slide,
.shoppingListPage.show-slide {
  padding-right: 20rem;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.wh-list {
  display: flex;
  align-items: center;
}
.wh-list .out-stock-title {
  opacity: 0.2;
}
.out-of-stock-user {
  position: relative;
  margin-right: 10px;
}

.out-of-stock-user .text {
  background-color: #ef7e1a;
  color: #fff;
  padding: 2px 4px;
  border-radius: 3px;
}

.search_category_name span {
  font-size: 11px;
}
.search_category_name span:hover {
  text-decoration: underline;
}
.search_cart_description {
  /* display: flex;
  padding-bottom: 0;
  justify-content: space-between; */
}
.search_category_name {
  /* margin-left: 5px; */
  white-space: nowrap;
  font-size: 12px;
  display: initial;
}
.search-pro-name span {
  /* color: #000000; */
  font-size: 16px;
}
.search-pro-price span {
  /* color: #000000; */
  font-size: 15px;
  font-weight: bold;
}

/* 16/05/2022 */
.items:hover .imgbox {
  opacity: 1 !important;
}

.timing_drop {
  padding-bottom: 20px;
}

.listing-wrapper {
  /* min-height: 800px; */
  min-height: 100vh;
  padding-top: 4rem;
}

@media (max-width: 639px) {

  .items .item_details {
    padding: 10px !important;
    min-height: 155px;
  }
  .listing-wrapper {
    min-height: 500px;
    padding-top: 0;
  }
  .refine-btn {
    margin-left: auto;
    padding: 0.2rem 1rem;
  }
  .filter-panel li {
    height: 30px;
  }
}
@media (max-width: 479px) {
  .items .item_details {
    min-height: 150px;
  }
  .items .price {
    font-size: 14px;
    display: contents;
    border: none;
  }
  .items .price span {
    margin: 0 5px;
  }
}
/* -------------------------Delivery and pickup popup -------------------------------*/
.delivery-box-title {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-size: 30px !important;
  font-weight: bold !important;
  margin: 0px auto !important;
  /* color: #3d9b7b; */
}
.delivery-box {
  display: flex;
  justify-content: center;
  gap:10px
}
.delivery-box-tab {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  /* background: #f57f183b !important; */
  padding: 30px 25px;
  margin: 15px;
  justify-content: space-between;
}
.delivery-box-tab-icon {
  display: flex;
  justify-content: center;
  min-height: 100px;
  margin-bottom: 30px;
}
.delivery-box-tab-icon img {
  height: auto;
  max-height: 80px;
}
.delivery-box-tab-dec {
  min-height: 100px;
  /* margin-bottom: 10px; */
}
.delivery-box-tab button {
  color: #ffffff;
  font-size: 15px;
  text-transform: uppercase;
  padding: 10px;
  outline: none;
  border-radius: 3px;
  width: max-content;
}

.delivery-box-tab-dec h2 {
  margin: 0 0 10px 0;
  padding: 0;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: bold;
}
.delivery-box-tab-dec p {
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
}

.pickup-table-box {
  width: 100%;
  overflow: hidden;
  display: flex;
}

.pickup_table_wrap {
  padding: 0;
  margin: 0;
  min-width: 700px;
  width: 100%;
  height: 80%;
  background-color: #fff;
  position: relative;
  overflow: hidden;
}

.pickup_table_inner {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  overflow: auto;
  /* font-family: "Roboto", sans-serif; */
  position: relative;
}
.pickup_table_inner .tbl_fixed_head a.tbl_left_arrow {
  padding: 0;
  margin: 0;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 122px;
  top: 2px;
  border: 0;
  outline: none;
  background: transparent;
  font-size: 40px;
  color: #fff;
  z-index: 5;
  text-decoration: none;
}
.pickup_table_inner .tbl_fixed_head a.tbl_right_arrow {
  padding: 0;
  margin: 0;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 18px;
  top: 2px;
  border: 0;
  outline: none;
  background: transparent;
  font-size: 40px;
  color: #fff;
  z-index: 5;
  text-decoration: none;
}

.pickup_table_inner .tbl_fixed_head {
  /* background-color: #f96c26; */
  padding: 5px 0 5px 130px;
  margin: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%;
  max-width: 912px;
  /* font-family: "Roboto", sans-serif; */
  display: flex;
  position: absolute;
}
.pickup_table_inner .tbl_fixed_head .cell {
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  flex-wrap: wrap;
}
.pickup_table_inner .tbl_fixed_head .cell p {
  padding: 0;
  margin: 0;
  font-size: 14px;
  width: 100%;
  text-align: center;
}
.pickup_table_inner .table thead th {
  border-bottom: 0;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  height: 20px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.pickup_table_inner .table thead th p {
  padding: 0;
  margin: 0;
  font-size: 12px;
}
.pickup_table_inner .table th,
.table td {
  border-top: 0;
}
.pickup_table_inner table {
  margin: 0;
  padding: 0;
  border-spacing: 0 !important;
  border-top: 0px !important;
  border-collapse: collapse;
}
.pickup_table_inner td,
.pickup_table_inner th {
  padding: 0;
  margin: 0;
}
.pickup_table_inner .timesec {
  padding: 0;
  margin: 28px 0 0 0;
  width: 130px;
  height: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.pickup_table_inner .timesec td {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  /* background: #3d9b7b; */
  font-size: 12px;
  border-bottom: #fff 1px solid;
  height: 49px;
}
.pickup_table_inner .teblesec {
  padding: 0;
  margin: 0;
  width: calc(100% - 130px);
  height: auto;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  display: flex;
}
.pickup_table_inner .teblesec .scrollwrap {
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
}
.pickup_table_inner .teblesec td {
  border: #e4e3e3 1px solid;
  border-collapse: collapse;
  border-top: 0;
  height: 48.5px;
  border-right: 0;
  width: auto;
}
.pickup_table_inner .teblesec td {
  border-top: 0px;
}
.pickup_table_inner .teblesec th {
  border: 0;
}
.tblcol {
  padding: 0;
  margin: 28px 0 0 0;
  display: inline-block;
}
.pickup_table_inner .teblesec td button {
  display: flex; /* padding:15px;*/
  align-items: center;
  justify-content: center;
  text-decoration: none;
  /* color: #3d9b7b; */
  font-size: 13px;
  height: 100%;
  cursor: pointer;
  width: 100%;
  border-radius: 0;
}
.card-msg{
  font-size: 11px !important;
  font-style: italic;
}
.pickup_table_inner .teblesec td button.timeclose {
  cursor: default;
  background-color: rgba(0, 0, 0, 0.26);
  color: #fff !important;
}
.pickup_table_inner th {
  padding: 15px;
}
.pickup_table_inner td {
  height: auto;
}
.pickup_table_inner tbody {
  display: block;
  overflow: auto;
}
.pickup_table_inner thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.pickup_table_inner thead {
  margin: 0;
  padding: 0;
  /* background: #f96c26; */
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-size: 20px;
  color: #fff;
  letter-spacing: -0.5px;
}
.slot_Address_view {
  display: flex;
  flex-direction: column;
  padding: 15px 30px 0 30px;
}
.popup_delivery_btn button {
  font-size: 13px;
  font-weight: bold;
}

.zip-code-box {
  padding: 10px 0;
}
.zip-box .MuiDialogActions-root button {
  /* background: #f57f18; */
  color: #ffffff;
  font-size: 16px;
  text-transform: uppercase;
  padding: 8px 25px;
  outline: none;
  border-radius: 3px;
  /* border: #f57f18 2px solid; */
}

.ac-button {
  justify-content: flex-end;
  display: flex;
}
.ac-button button {
  /* background: #f57f18; */
  color: #ffffff;
  font-size: 16px;
  text-transform: uppercase;
  padding: 8px 25px;
  outline: none;
  border-radius: 3px;
  /* border: #f57f18 2px solid; */
}
/* .ac-button button:hover {
  color: #f57f18;
  background: #ffffff;
} */
.address-pop-title {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  align-items: baseline;
}



.address-pop-title .new_add {
  /* color: #eb7a17; */
  border-radius: 50px;
  display: inline-block;
  padding: 7px 20px;
  /* border: 1px solid #f57f18; */
  line-height: 1;
  margin-bottom: -12px;
  cursor: pointer;
  font-size: 15px;
}
.address-pop-title .new_add span {
  margin-right: 10px;
}
.change-add {
  /* color: #f57f18; */
  cursor: pointer;
  font-weight: bold !important;
}
.rp-slot {
  display: none;
}
.cross-color {
  color: #389978;
}
.pickupDelivery_Popup_Today_title {
  // color: #389978 !important;
  font-weight: bolder !important;
}

.deparment-results {
  // position: absolute;
  min-width: 60vw;
  box-shadow: 0px 10px 20px #00000033;
  // background: #fff;
  // z-index: 9999;
  // left: 16%;
  // top: 35px;
  overflow: auto;
  width: min-content;
  max-height: 70vh;
  min-height: 50vh;
}

.search-results {
  left: 0;
  top: 50px;
  width: 45vw;
}

.emtpyCartMsg {
  display: flex;
  justify-content: center;
  min-height: 307px;
  font-size: 31px;
  color: gray;
  align-items: center;
}
.sideemtpyCartMsg {
  display: flex;
  justify-content: center;
  min-height: 100%;
  font-size: 20px;
  color: gray;
  align-items: center;
}
.cust_pickup_msg {
  width: 250px;
}
.item_listing_homepage {
  display: flex !important;
  overflow: hidden;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding: 15px;
  gap:5px;
}
.item_listing_homepage_mob {
  display: none !important;
}
.product_empty_brand{
  font-size: 1.8rem !important;
}
.header-desktop{
  display: block;
}

@media (max-width: 1023px) {
  .pickup_table_inner .timesec {
    position: fixed;
    z-index: 999999;
  }
  .pickup_table_inner .teblesec {
    margin-left: 130px;
  }
}
@media (max-width: 900px) {
  .header-desktop{
    display: none !important;
  }

  .casecard-shoplist{
    flex-direction: column;
    gap: 5px;
  }
  .details_desc_wrapper .details_pricerow span {
    font-size: 14px;
    text-decoration: none;
    color: #fff;
    display: block;
    padding: 0;
  }
  .addNotes,
  .addNotes svg {
    display: flex;
    font-size: 20px !important;
    align-items: center;
    margin-right: 5px !important;
  }
  .details_desc_wrapper .note_box {
    padding: 0;
    display: flex;
    justify-content: end;
    margin-right: 15px;
    margin-top: 10px;
  }
  .brand-content {
    padding: 10px 0;
  }
  #form-container {
    width: auto !important;
    min-height: 150px !important;
  }
  #card-number,
  #card-holder {
    width: auto !important;
  }
  #exp-container {
    display: block !important;
    float: none !important;
    width: 50% !important;
    margin-left: 0 !important;
  }
  .item-subdes-container span {
    position: relative;
    top: 0;
    right: 0;
  }
  
  .delivery-box-title,
  .popUp .header span,
  .MuiTypography-body1 {
    font-size: 16px !important;
  }
  .listing-wrapper {
    // padding-top: 3rem;
  }
  .popup_delivery_btn.desk {
    display: none;
  }
  .rp-slot {
    display: block;
    text-align: right;
    padding-top: 15px;
  }
  .topmenu.menu-items li a {
    color: #000 !important;
    font-weight: bold;
  }
  .leftmenu-main .closeBtn {
    margin: 5px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .name-section {
    font-size: 14px;
    font-weight: bold;
  }

  .user_main_wrapper .user_details_desc {
    overflow-x: hidden;
    margin-bottom: 1rem;
    max-height: fit-content;
  }
  .cart_details .count_box {
    display: inline-flex !important;
    height: fit-content;
    width: 40px;
  }
  .cart_details .count_box:hover {
    display: inline-flex !important;
  }
  .address_user .add_more_opt .sub_add_details {
    width: 100px;
  }

  .filter .searchform .form-group {
    position: inherit !important;
  }
  .filter .category {
    position: relative;
    padding: 0;
    margin-right: 5px;
  }
  .filter .searchMobile {
    padding-top: 0;
    left: 0;
    width: 100%;
    margin-left: 1vw;
    top: 45px;
    padding: 0;
  }
  .mobile-rs {
    position: relative;
    height: 345px;
  }
  .mobile-rs .showAll {
    bottom: -15px;
    border-bottom: #ffffff 10px solid;
  }
  .mob-st img {
    margin-top: -7px;
  }
  .lx .count_box .count_no {
    min-width: 33px;
  }
  .pickup_table_inner .timesec {
    margin-top: 27px;
    /* border-top: #f96c26 28px solid; */
  }

  .item_listing_homepage .items {
    // width: 31%;
    min-height: 150px;
  }
  .mobi-bottom-navigation {
    display: block;
  }
  .leftmenu.openmenu {
    width: auto;
    overflow: hidden !important;
  }
}
@media (max-width: 767px) {
  .cart_details_count .prod_cost,
  .bttn-fill1 .empty_Btnmob,
  .bttn-fill1 button,
  .order_placed .order_success_details p {
    font-size: 15px !important;
  }
  .items .add_tocart {
    display: none !important;
  }
  .delivery-box {
    flex-direction: column;
  }
  .delivery-box-tab {
    max-width: 100%;
    margin: 0 0 25px 0;
    padding: 8px 35px !important;
  }
  .delivery-box-tab-icon {
    display: none;
  }
  .delivery-box-tab button {
    padding: 5px !important;
  }
  .pickup_table_inner .tbl_fixed_head {
    padding-left: 80px;
    width: calc(100% - 97px);
  }
  .tblcol {
    width: calc(100% - 97px);
  }
  .pickup-table-box {
    overflow: hidden;
  }
  .pickup_table_inner .timesec {
    width: 80px;
  }
  .pickup_table_inner .teblesec {
    margin-left: 80px;
    width: calc(100% - 80px);
  }

  .delivery-box-title {
    font-size: 20px;
  }
  .address-pop-title h4 {
    font-size: 18px;
    align-self: center;
    margin-bottom: 0;
  }
  .address-pop-title .new_add {
    font-size: 16px;
    padding: 0.2rem;
  }
  .item_listing_homepage {
    display: none !important;
  }
  .item_listing_homepage_mob {
    display: block !important;
  }
  .item_listing_homepage_mob .items {
    /* width: 97% !important;
    min-height: 150px; */
    width: 100% !important;
    min-height: 150px;
    max-width: 100% !important;
    margin: 0;
  }
  .items:hover .add_tocart {
    display: flex;
  }
}

@media (max-width: 479px) {
  .delivery-box-title {
    font-size: 12px;
  }
  .delivery-box-tab-dec h2 {
    font-size: 14px;
    width: auto;
  }
  .delivery-box-tab-dec p {
    font-size: 12px !important;
  }
  .name-section span {
    font-size: 14px;
  }
  .name-section p {
    font-size: 11px;
  }
  .name-section .icon-left-arrow {
    color: #000000 !important;
    font-weight: bold;
    font-size: 16px;
  }
  .pickup_table_wrap {
    min-width: 450px;
  }
}

@media (max-width: 359px) {
  .delivery-box-title {
    font-size: 15px;
  }
}

.MuiCollapse-wrapper {
  color: #000000;
  font-weight: bold;
  text-transform: capitalize;
  letter-spacing: 1px;
}

.MuiTreeItem-root .MuiTreeItem-content .MuiTreeItem-label {
  font-weight: bold;
  text-transform: capitalize;
  letter-spacing: 1px;
}
.ProductNotavail_zipTitle {
  color: red;
  font-size: 10px;
}

.react-tel-input .form-control {
  width: 100% !important;
}
.react-tel-input .form-control:focus {
  border-color: #f57f18 !important;
  box-shadow: 0 0 0 1px #f57f18 !important;
}
.pop_close {
  position: absolute;
  top: 10px;
  right: 10px;
}
.preloader {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: end;
}

.MuiTab-textColorInherit.sortingTablabel {
  opacity: 1;
  outline: none !important;
  color: #f57f18;
}
.MuiTab-textColorInherit.sortingTablabel.Mui-selected {
  font-weight: bolder;
  border: 2px solid;
}
.MuiTabs-indicator {
  background-color: transparent !important;
}
.nav-tabs #uncontrolled-tab-example-tab-null {
  padding: 0px;
  background: #fff;

  display: flex;
  margin-left: auto;
  border: none;
}
.list_cat_wrapper {
  min-width: 280px;
  height: 100vh;
  /* overflow: auto; */
  border-right: 1px solid lightgray;
}
.MuiTreeItem-label:hover {
  background-color: rgb(0 0 0 / 12%) !important;
}
.homeBrandbox {
  display: flex;
}
.mobi-footer-drop .MuiAccordion-rounded {
  background-color: transparent;
  color: #3e3e3ee8;
}
.bttn-fill1 .empty_Btnmob {
  display: none;
}
.offerImages {
  display: block;
}
.moblieOfferimg {
  display: none;
}
.imgMagnify img {
  width: 100%;
}
.new-category-sidebar {
  position: absolute;
  background: #ffffff;
  width: 22%;
  // display: block;
  height: 100vh;
  transition: all 0.7s;
}
.newCategory-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 12px;
  cursor: pointer;
  transition: all 0.3s;
  align-items: center;
  border-top: 1px solid #d1d1d1;
}
.listing-group-categoryname-section {
  font-size: 25px;
  /* padding: 10px 25px; */
  padding: 5px 0px 10px 25px;
  font-weight: 600;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
}
.listing-group-categoryname-section .item-count {
  background: white;
  border-radius: 10px;
  padding: 1px 7px;
  /* color: #f57f18; */
  margin-left: 10px;
  font-size: 13px;
  font-weight: 500;
}
.newCategory-item:hover .newCategory-item p {
  /* background-color: #389978; */
  color: white;
  font-weight: 600;
}

.new-category-sidebar .list_cat_wrapper div .newCategory-item:hover {
  width: 100% !important;
}

header > .category:hover header > .new-category-sidebar {
  display: block;
  height: 100vh;
  background-color: red;
}
/* 13 sep listin item mobile view change 1 item to 2 item per row */
@media (max-width: 639px) {
  .items-details {
    padding: 11px 0px 0px 0px;
    display: block;
  }
  .list_main_content .item_listing_details .items {
    width: calc(100% - 5px) !important;
    min-height: 0px;
    max-width: 100% !important;
    border-radius: 9px;
  }
  .items .imgbox {
    width: 35%;
    margin-right: 20px;
    margin-top: 0px;
    float: left;
    height: 150px;
  }
  /* begin old */
  .newsalee span {
    font-size: 10px;
    padding: 3px 0;
    border: 3px solid #409b79;
  }
  /* end */
  .newsalee p {
    font-size: 10px;
    padding: 3px 0;
    border: 3px solid #409b79;
  }

  .item_details .textbox {
    min-height: 25px;
    max-width: 100%;
    text-align: left;
    display: block;
    justify-content: start;
  }
 
}

.mobi-footer-drop {
  display: none;
}
.mob_tablising {
  display: none;
}



@media (max-width: 991px) {
  .cust_details .figure img {
    max-height: 100px;
  }
}
@media (max-width: 900px) {
  .filter-container {
    position: relative;
  }
  .home-product-category .newcategory,
  .home-product-category .cat-img {
    width: 100%;
    min-width: auto;
    height: auto;
  }
  .home-product-category a, .product-category-container a{
    left: 5px;
  }
  .product-category-container{
    padding: 0;
    gap: 5px;
  }
  .product-category-container .product-category-item{
    width: calc(95%/3);
  }
  .menu_body{
    padding: 5px;
  }
  .details_desc_wrapper .details_head_wrapper {
    border: none;
  }
  .bottom-price {
    font-size: 18px !important;
  }

  .refine-btn {
    padding: 5px !important;
  }
  .searchform {
    height: inherit;
  }
  .searchform .form-control {
    font-size: 15px !important;
  }
  // .item-addtocart-container .button-plusminus {
  //   display: none !important;
  // }
  .pickup_table_inner tbody {
    overflow: hidden;
  }
  .main-data {
    padding-top: 0;
  }
  header {
    position: relative;
  }
  .mob-category-icon-container::-webkit-scrollbar {
    width: 0;
  }
  .newCategory-item-icon {
    height: 50px;
    display: flex;
    align-items: center;
    width: 90px !important;
    justify-content: center;
    border-top: 1px solid #e0e0e0;
    padding: 4px 10px;
    width: 100%;
  }
  .newCategory-item {
    height: 50px;
    border-top: 1px solid #e0e0e0;
  }
  .mob-category-list {
    width: 100%;
  }
  .list_main_content.show_sidecart {
    overflow-x: hidden;
  }
  .items:hover .add_tocart {
    display: none;
  }
  .rp-slot {
    /* position: absolute; */
    top: 57px;
    left: 0;
    width: 100%;
    text-align: right;
    margin: 0;
    padding: 0;
    z-index: 9;
  }
  .rp-slot button {
    border: none;
    outline: none;
    resize: none;
    padding: 5px;
    /* margin-right: 10px; */
  }

  /* .cust_head_main{ padding-bottom: 25px;} */
  .order_again .bttn-fill .bttn_grn {
    font-size: 12px !important;
    margin-bottom: 10px;
  }
  .details_img_caro .fluid img {
    height: auto !important;
    max-height: 300px;
  }
  .show_sidecart .listing_details {
    display: none;
  }
  .details_main .addNoteSed {
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
  }
  .details_img_caro img {
    width: auto;
  }
  .list_main_content.show_sidecart {
    padding-right: 0;
  }
  .footer-top .f_topblog {
    display: flex;
    justify-content: center;
    padding: 5px 0px !important;
  }
  .line_count {
    display: none !important;
  }
  .mob_tablising {
    display: flex;
    justify-content: end;
    position: absolute;
    width: 100%;
    margin-top: 10px;
    padding-right: 8px;
  }
  .bttn-fill1 .empty_Btnmob {
    display: inline-flex;
  }
  .MuiDialogTitle-root {
    padding: 0px !important;
  }
  .sidenav {
    width: 300px !important;
    top: 0 !important;
  }
  .cart-listing-details {
    max-width: 280px;
    max-height: fit-content;
    min-height: 130px !important;
  }
  .reorderpopup .MuiDialog-paper {
    padding: 6px;
    margin: 10px;
    width: 100%;
  }
  .listing_details {
    // display: none;
  }

  .items .item_details {
    width: 85% !important;
    /* min-height: 150px; */
    position: initial;
  }

  .cart_main_wrap .cart_sub_details {
    padding-bottom: 10px;
    padding-top: 10px;
    margin-bottom: 10px;
  }
  .topmenu li a {
    justify-content: flex-start;
    gap: 10px;
  }
  .items .no_selected img {
    display: block !important;
  }
  .inner-section .topmenu {
    padding-bottom: 0px;
  }

  .App .main-content {
    height: 100vh;
  }
  .delivery-box-tab {
    padding: 30px 15px;
    margin: 10px;
  }
  .offerImages {
    display: none;
  }
  .moblieOfferimg {
    display: block;
  }
  .listing-group-categoryname-section {
    font-size: 18px;
    letter-spacing: 0;
    padding: 0;
    margin: 9px 1rem;
    // width: 70%;
  }

  .listing-group-categoryname-section span {
    font-size: 15px !important;
  }
  .listing-group-categoryname-section .item-count {
    font-size: 12px !important;
    margin-left: 1rem !important;
  }
}
@media (max-width: 767px) {
  .delivery-box-tab button {
    font-size: 15px;
  }
  .smll_img img {
    max-height: 100px;
    max-width: 80px;
    // min-width: 80px;
  }
  .delivery-box-tab-dec {
    min-height: 20px;
    width: 100%;
    text-align: center;
  }
  .shoppinglist-mob {
    margin-left: 0;
    margin-bottom: 5rem;
  }
  .mobi-footer-drop .MuiAccordionSummary-content {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 15px;
  }
  
  .footer-top .f_topblog {
    padding: 7px 0;
  }



  .details_main .navBttns {
    position: relative !important;
    top: 174px !important;
  }

  .slotPopup .MuiDialog-paper {
    margin: 10px !important;
  }
  .slotPopup .MuiDialog-paper {
    padding: 15px 8px !important;
  }

  .count_box input {
    max-width: 100%;
    padding: 0 !important;
    margin: 0 !important;
    background: transparent !important;
    max-height: 100% !important;
  }
  .pickup_table_inner .tbl_fixed_head .cell p {
    font-size: 12px;
  }

  .cart-listing-details .cart_sub_details {
    margin-bottom: 0px;
    border: none;
  }
  .note_box .note {
    font-size: 10px;
    width: 80%;
  }
  .main-banner {
    padding: 10px;
  }
  .home-product-category {
    padding: 10px;
  }

  .items:hover .item_details {
    z-index: 8;
  }
  .cart_desc_name p {
    margin-right: 1rem;
  }
  .singleAddtocartmob .count_boxmob {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 15%;
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
  }
  .singleAddtocartmob .count_btnmobminus {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 30%;
    border-radius: 0px;
    background: 0;
  }
  .singleAddtocartmob .count_nomob {
    height: 40%;
  }
  .singleAddtocartmob .count_nomob input {
    height: 100%;
    text-align: center;
    font-size: 1.5rem;
    border: none;
  }

  .add-count .count_box {
    display: none;
  }

  .singleAddtocartmob button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    min-width: 15%;
    font-weight: normal;
    font-size: 14px;
    color: #fff;
    border: none;
    z-index: 99;
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
  }
  .singleAddtocartmob {
    display: block;
  }
  .count_boxmob {
    display: block !important;
  }
  .chooseSlotMob {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 479px) {
  .card-items img {
    width: 35px !important;
  }
  .delivery-box-tab button {
    font-size: 10px;
    margin-top: 5px;
  }
  .rp-slot {
    top: 62px;
  }
  .rp-slot button {
    font-size: 11px !important;
    width: 100%;
    max-width: 180px;
    text-align: right;
  }
  .details_img_caro .fluid img {
    max-height: 300px;
  }
  .details_desc_wrapper {
    padding-top: 2rem;
  }

  .cust_pickup_msg {
    width: 200px;
  }
  .delivery-box-tab {
    max-width: 100%;
    margin: 0 0 15px 0;
    padding: 8px 35px !important;
    display: block;
  }
}


/*start New card design */

#amount {
  font-size: 12px;
}

#amount strong {
  font-size: 14px;
}

#card-back {
  top: 40px;
  right: 0;
  z-index: -2;
}

#card-btn {
  background-color: rgba(251, 251, 251, 0.8);
  color: #ffb242;
  position: absolute;
  bottom: -55px;
  right: 0;
  width: 150px;
  border-radius: 8px;
  height: 42px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  outline: none;
  border: none;
  cursor: pointer;
}

#card-btn:hover {
  background-color: rgba(251, 251, 251, 1);
}

#card-cvc {
  width: 65px;
  margin-bottom: 0;
  color: #000;
}

#card-image {
  float: right;
  height: 100%;
}

#card-image i {
  font-size: 40px;
}

#card-month {
  width: 45% !important;
  color: #000;
}

#card-number,
#card-holder {
  width: 100%;
  color: #000;
  padding: 5px;
}

#card-stripe {
  width: 100%;
  height: 55px;
  background-color: #3d5266;
  position: absolute;
  right: 0;
}

#card-success {
  color: #00b349;
}

#card-token {
  display: none;
}

#card-year {
  width: 45%;
  float: right;
  color: #000;
}

#cardholder-container {
  width: 60%;
  display: inline-block;
}

#cvc-container {
  position: absolute;
  width: 110px;
  right: -115px;
  bottom: -10px;
  padding-left: 20px;
  box-sizing: border-box;
}

#cvc-container label {
  width: 100%;
}

#cvc-container p {
  font-size: 6px;
  text-transform: uppercase;
  margin-top: 3px;
  letter-spacing: 0.5px;
}

#form-container {
  margin: auto;
  width: 500px;
  min-height: 130px;
  position: relative;
}

#form-errors {
  color: #eb0000;
}

#form-errors,
#card-success {
  background-color: white;
  width: 500px;
  margin: 0 auto 10px;
  height: 50px;
  border-radius: 8px;
  padding: 0 20px;
  font-weight: 400;
  box-sizing: border-box;
  line-height: 46px;
  letter-spacing: 0.5px;
  text-transform: none;
}

#form-errors p,
#card-success p {
  margin: 0 5px;
  display: inline-block;
}

#exp-container {
  margin-left: 10px;
  width: 32%;
  display: inline-block;
  float: right;
}


#image-container {
  width: 100%;
  position: relative;
  height: 55px;
  margin-bottom: 5px;
  line-height: 55px;
}

#image-container img {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  border-radius: 10px;
  mix-blend-mode: multiply;
}

.credit-card-input {
  border: none;
  outline: none;
  border: 1px solid #bcbcbc;
  height: 40px;
  line-height: 30px;
  padding: 0 10px;
  /* margin: 0 0 25px; */
  color: white;
  font-size: 14px;
  box-sizing: border-box;
  border-radius: 4px;
  /* font-family: lato, 'helvetica-light', 'sans-serif'; */
  letter-spacing: 0.7px;
}
.credit-card-input .MuiInputBase-root {
  /* color:#fff; */
  padding: 6px;
  border: 1px solid #bcbcbc;
  border-radius: 5px;
}

.credit-card-input .MuiInput-underline:before,
.MuiInput-underline.Mui-focused:after,
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border: none !important;
}

.credit-card-input::-webkit-input-placeholder {
  opacity: 0.7;
  /* font-family: lato, 'helvetica-light', 'sans-serif' ; */
  letter-spacing: 1px;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 14px;
}

.credit-card-input:-moz-placeholder {
  opacity: 0.7;
  /* font-family: lato, 'helvetica-light', 'sans-serif' ; */
  letter-spacing: 1px;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 14px;
}

.credit-card-input::-moz-placeholder {
  opacity: 0.7;
  /* font-family: lato, 'helvetica-light', 'sans-serif'; */
  letter-spacing: 1px;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 14px;
}

.credit-card-input:-ms-input-placeholder {
  opacity: 0.7;
  /* font-family: lato, 'helvetica-light', 'sans-serif' ; */
  letter-spacing: 1px;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 14px;
}

.credit-card-input.invalid {
  border: solid 2px #eb0000;
  height: 34px;
}

.credit-card-label {
  display: block;
  margin: 0 auto 7px;
  font-size: 13px;
}

#shadow {
  position: absolute;
  right: 0;
  width: 284px;
  height: 214px;
  top: 36px;
  background-color: #000;
  z-index: -1;
  border-radius: 8px;
  box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.1);
}

.debit-card {
  width: 300px;
  height: 180px;
  padding: 20px;
  background-color: #0093e9;
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
  position: relative;
  border-radius: 5px;
  box-shadow: 3px 3px 5px #0000001a;
  transition: all 0.3s ease-in;
  cursor: pointer;
  margin-left: 10px;
}
.debit-card:nth-child(odd) {
  background-image: linear-gradient(160deg, #3b7fa8 0%, #29edd8 100%);
}
.debit-card:nth-child(even) {
  background-image: linear-gradient(116deg, #29bbe1 0%, #912ac1 100%);
}

.debit-card:hover {
  box-shadow: 5px 3px 5px #000000a2;
}

.card-2 {
  background-color: #21d4fd;
  background-image: linear-gradient(116deg, #21d4fd 0%, #b721ff 100%);
}

.text-muted {
  font-size: 0.8rem;
}

.text-white {
  font-size: 14px;
}

.input-section {
  position: absolute;
  top: 6px;
  right: 0;
}

.card-selection-radio {
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: #eee;
  position: relative;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.card-save-container {
  min-width: 100%;
  max-width: 500px;
  flex-wrap: wrap;
}
.card-d-grid {
  display: grid;
  justify-items: center;
}
.card-selection-radio:after {
  position: absolute;
  width: 100%;
  height: 100%;
  /* font-family: "Font Awesome 5 Free"; */
  font-weight: 600;
  content: "\f00c";
  clip-path: polygon(
    21% 39%,
    43% 57%,
    99% 13%,
    100% 39%,
    40% 80%,
    39% 84%,
    5% 53%
  );
  background: white;
  padding: 15px;
  color: #fff;
  font-size: 15px;
  display: none;
}

.card-selection-radio:checked,
.card-selection-radio:checked:hover {
  background-color: blue;
}

.card-selection-radio:checked::after {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-selection-radio:hover {
  background-color: #ddd;
}

.debit-card .card-img {
  width: 50px;
  height: 32px;
  object-fit: cover;
  border-radius: 10px;
}

.credit-card-container {
  border: 1px solid #d9d9d9;
  width: 60%;
  padding: 2rem;
}
.card-items {
  padding: 5px;
  cursor: pointer;
  border-radius: 1rem;
}
.card-items img {
  width: 40px;
  mix-blend-mode: multiply;
}

/*end New card design */

@media (max-width: 1024px) {
  
  .items-details {
    padding-top: 12px;
  }
  .item-addtocart-container .button-addtocart{
    opacity: 1 !important;
  }
  .cart-popular-product-section {
    display: none;
  }
  .cart_wrapper .cartdetails {
    margin-left: 0;
  }

  .cart-section-m-30 {
    margin-left: 0;
  }
}
@media only screen and (max-width: 900px) and (min-width: 768px) {
  .items .price {
    position: static !important;
    padding-top: 15px;
  }
  
}

.dropbtn {
  border: none !important;
  cursor: pointer !important;
  width: max-content !important;
  /* border-left: 1px solid #c2bcbc !important; */
  background-color: #fff !important;
  margin: 5px 7px;
  align-items: center;
  gap: 1rem;
 
}

.dropdown {
  position: relative;

  align-self: end;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  max-height: 50vh;
  z-index: 100;
  overflow-x: auto;
  left: -2rem;
  margin-top: 3px;
  border-radius: 10px;
  padding: 5px;
}

.dropdown-content .cat-item {
  cursor: pointer;
  color: black;
  padding: 5px 6px;
  text-decoration: none;
  display: block;
  border-top: 1px solid #c2bcbc;
}

.dropdown-content .cat-item:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.card-label {
  font-size: 20px;
  text-transform: capitalize;
}

/* BlinkingCursor.css */
@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.blink-cursor::after {
  content: "|"; /* Cursor character */
  opacity: 1;
  animation: blink 1s infinite;
}

/* Case toggle */
.casebutton {
  position: relative;
  top: 50%;
  width: 100px;
  height: 40px;
  /* margin: -20px auto 0 auto; */
  overflow: hidden;
  border: 1px solid red;
}

.casebutton.b2 {
  border-radius: 6px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

/* Button 10 */
#button-10 .knobs:before,
#button-10 .knobs:after,
#button-10 .knobs span {
  position: absolute;
  top: 4px;
  width: 25px;
  height: 10px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  border-radius: 2px;
  transition: 0.3s ease all;
}

#button-10 .knobs:before {
  content: "";
  left: 4px;
  background-color: #03a9f4;
  padding: 15px 22px;
}

#button-10 .knobs:after {
  content: "Case";
  right: 13px;
  color: #4e4e4e;
  top: 13px;
  font-size: 13px;
}

#button-10 .knobs span {
  display: inline-block;
  left: 5px;
  color: #fff;
  z-index: 1;
  top: 13px;
}

#button-10 .checkbox:checked + .knobs span {
  color: #4e4e4e;
  font-size: 13px;
}

#button-10 .checkbox:checked + .knobs:before {
  left: 55px;
  background-color: #f44336;
  padding: 15px 20px;
}

#button-10 .checkbox:checked + .knobs:after {
  color: #fff;
}

#button-10 .checkbox:checked ~ .layer {
  background-color: #fcebeb;
}

.searchMobile ul {
  list-style: none;
}
.searchMobile ul li {
  border-bottom: 1px solid gray;
}
.searchMobile ul li img {
  // width: 50px;
  height: 50px;
}

.item-addtocart-container .button-addtocart {
  position: absolute;
  bottom: 0px;
  right: 6%;
  opacity: 0;
  border: none;
  background: none;
  z-index: 99;
}
.item-addtocart-container .button-plusminus {
  position: absolute;
  bottom: 0;
  right: 9%;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  border-radius: 5px;
  color: #fff;
  z-index: 99;
}
.item-addtocart-container input[type="text"] {
  width: inherit;
  text-align: center;
  background: transparent;
  margin: 0 1rem;
  border: none;
  color: white;
}

.item-addtocart-container .case-box {
  display: flex;
  justify-content: center;
  font-size: 14px;
  bottom: 31%;
  padding-top: 3px;
}

.items:hover .item-addtocart-container .button-addtocart {
  animation: buttonPopup 0.5s cubic-bezier(0.21, 0.98, 0.6, 0.99) forwards;
}
@keyframes buttonPopup {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

header nav ul.top-nav li:last-child {
  border-right: none;
}

// header nav ul.top-nav li :hover {
//   background-color: #fff;
// }

.left-menu-inner {
  width: 280px;
  height: 90vh;
  overflow: overlay;
  padding-bottom: 50px;
  box-sizing: border-box;
}
.case-box input[type="checkbox"] {
  width: 17px;
}

.case-line .case-checkbox {
  transform: scale(1.4);
  margin-right: 5px;
}
.recent-item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 5px 35px;
  font-weight: bold;

  &:hover {
    background-color: gainsboro;
  }
}
.recent-item span {
  font-size: 20px;
}

.continuebtn {
  margin-left: 10%;
}

.testul {
  
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 1em;
  -moz-column-gap: 1em;
  column-gap: 1em;
  -moz-column-gap: 3.5em;
  -webkit-column-gap: 3.5em;
  column-gap: 3.5em;
  font-size: 0.85em;
  display: inline-block;
}

.cat-item {
  display: flex;
  margin: 0 1rem 1.5em 0;
  // width: 100%;
  -webkit-transition: 1s ease all;
  // background-color: rgba(241, 241, 241, 0.6);
  // box-shadow: 0 0 12px 9px rgba(241, 241, 241, 0.6);
  flex-direction: column;
  // width: 30% !important;
  padding: 10px;
}
.home-category-menu{
  position: relative;
  display: block;
}
.home-category-menu .sub-category-menu{

  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: 0;
  display: none;
  z-index: 999;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 0px 10px 0 rgba(0, 0, 0, 0.19);
}
.home-category-menu:hover > .sub-category-menu,
.home-category-menu:focus-within > .sub-category-menu,
.home-category-menu .sub-category-menu:hover,
.home-category-menu .sub-category-menu:focus {
  visibility: visible;
  opacity: 1;
  display: block
}



.testul .cat-item p {
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  margin: 5px 10px;
}

.backdrop{
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 9999;
}

.container-box{
  display: flex;
  gap: 1rem;
}
.customer-list{
  padding: 0;
  li{
    cursor: pointer;
    border-bottom: 1px solid #d3d3d3;
  }
}

.customer-list li:hover{
  background-color: #d3d3d3;
    }

.side-address-panel{
  position: absolute;
  left: 100%;
  background: #fff;
  width: 250px;
  padding: 10px;
  top: 3rem;
  z-index: 9;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
}

.menubg {
  padding: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.3;
}

