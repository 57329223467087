@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?');
  src: url('../fonts/icomoon.eot?') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?') format('truetype'),
  url('../fonts/icomoon.woff?') format('woff'),
  url('../fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"], [class*=" icon-"] { 
  /* use !important to prevent issues with browser extensions that change font */
  font-family: 'icomoon', sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-time:before {
  content: "\e901";
}
.icon-cvv:before {
  content: "\e902";
}
.icon-supermarket:before {
  content: "\e903";
}
.icon-facebook:before {
  content: "\e904";
}
.icon-sort:before {
  content: "\e905";
}
.icon-search:before {
  content: "\e906";
}
.icon-print:before {
  content: "\e907";
}
.icon-tick-circle:before {
  content: "\e908";
}
.icon-pdf:before {
  content: "\e909";
}
.icon-more:before {
  content: "\e90a";
}
.icon-menu:before {
  content: "\e90b";
}
.icon-menu-mob:before {
  content: "\e90c";
}
.icon-logout:before {
  content: "\e90d";
}
.icon-like:before {
  content: "\e90e";
}
.icon-left-arrow:before {
  content: "\e90f";
}
.icon-setting:before {
  content: "\e910";
}
.icon-icecream:before {
  content: "\e911";
}
.icon-heart:before {
  content: "\e912";
}
.icon-credit-card:before {
  content: "\e93f";
}
.icon-sandwitch:before {
  content: "\e913";
}
.icon-candy:before {
  content: "\e914";
}
.icon-fish:before {
  content: "\e915";
}
.icon-meat:before {
  content: "\e916";
}
.icon-fruits:before {
  content: "\e917";
}
.icon-household:before {
  content: "\e918";
}
.icon-beer:before {
  content: "\e919";
}
.icon-sushi:before {
  content: "\e91a";
}
.icon-veggies:before {
  content: "\e91b";
}
.icon-grid:before {
  content: "\e91c";
}
.icon-security:before {
  content: "\e91d";
}
.icon-refresh:before {
  content: "\e91e";
}
.icon-vehicle:before {
  content: "\e91f";
}
.icon-support:before {
  content: "\e920";
}
.icon-support:before {
  content: "\e920";
}
.icon-visibility:before {
  content: "\e900";
}
.icon-Close:before {
  content: "\e921";
}
.icon-close:before {
  content: "\e922";
}
.icon-dairy:before {
  content: "\e923";
}
.icon-camera:before {
  content: "\e924";
}
.icon-cake:before {
  content: "\e925";
}
.icon-pin:before {
  content: "\e926";
}
.icon-arrowright:before {
  content: "\e927";
}
.icon-alert:before {
  content: "\e928";
}
.icon-twitter:before {
  content: "\e929";
}
.icon-account:before {
  content: "\e92a";
}
.icon-pinterest:before {
  content: "\e92b";
}
.icon-youtube:before {
  content: "\e92c";
}
.icon-instagram:before {
  content: "\e92d";
}
.icon-arrowleft:before {
  content: "\e92e";
}
.icon-plus:before {
  content: "\e92f";
}
.icon-minus:before {
  content: "\e930";
}
.icon-user-main:before {
  content: "\e931";
}
.icon-dot-menu:before {
  content: "\e932";
}
.icon-tick:before {
  content: "\e933";
}
.icon-box:before {
  content: "\e934";
}
.icon-invisible:before {
  content: "\e935";
}
.icon-heart-like:before {
  content: "\e936";
}