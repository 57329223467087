/*
  =========================================================================================
                                    Social Icons
  =========================================================================================
  */
  
	
  .round-btn {display: inline;height: 40px; width: 40px; background:#fff;border-radius: 50%;float: left;margin: 15px 8px;box-shadow: 2px 2px 5px 0px rgb(82, 0, 67);border: 1px solid gainsboro;}
  .round-btn a {display: block !important;padding: 7px 12px;font-size: 18px;border-radius: 50%;}
  .round-btn .icon {padding: 3px;}
  .round-btn .icon img{height: 24px; width: 32px;margin-top: 6px;}
  .icon-facebook {color: #3b5998;padding: 8px 13px;    display: contents;}
  .icon-linkedin  {color: #007bb6; display: contents;}
  .icon-twitter {color: #1c9deb; display: contents;}
  .icon-instagram {color: #dd3f5c; display: contents;}
  .icon-whatsapp {color: #155E54; display: contents;}
  .icon-youtube {color: #D6403A; display: contents;}
  .icon-envelop {color: #D6403A;font-size: 15px; padding: 9px 12px;}
  .standard_header .standard_social_links {margin-left: 1rem;display: flex;justify-content: end;}

    /*
    =========================================================================================
                                      footer
    =========================================================================================
    */
    .row{
        margin-right: 0 !important;
    }
    .container-fluid{
        padding-right: 0 !important;
    }
    .footer-wrap {
     
      background-size: cover;
  }
  .footer-service{
    display: flex;
    padding: 0 2rem 
  }
  .standard_social_links {display: flex;justify-content: end;}
  
  .footer-wrap h3 {
    font-size: 17px;
    text-transform: uppercase;
    margin:15px 0 10px 0;
    font-weight: 600;
  }
  
  .footer-wrap p {
      font-size: 13px;
      line-height: 24px;
      /* color: #040404; */
      /* margin-top: 15px; */
  }
  
  .footer-wrap p a {
    color: #2e2d2d;
      text-decoration: underline;
      font-style: italic;
  }
  
  .footer-wrap p a:hover {
      text-decoration: none;
      color: #ff7800;
  }
  
  .footer-links li a {
      font-size: 13px;
      line-height: 30px;
      color: #878787;
      text-decoration: none;
      font-weight: 700;
  }
  .footer-links li a:hover{
    color: #222;
  }
  .footer-links li:before {
   
      font-family: 'FontAwesome';
      padding-right: 10px;
      color: #ccc;
  }
  
  .footer-category li a {
      font-size: 13px;
      line-height: 30px;
      color: #ccc;
      text-decoration: none;
  }
  
  .footer-category li:before {
   
      font-family: 'FontAwesome';
      padding-right: 10px;
      color: #b3b3b3;
  }
  
  .address {
      
      color: #b3b3b3;
      font-size: 14px;
      position: relative;
      padding-left: 30px;
      line-height: 30px;
  }
  
  .address:before {
      content: "\f277";
      font-family: 'FontAwesome';
      position: absolute;
      top: 0;
      left: 0;
  }
  
  .info a {
   
      color: #b3b3b3;
      font-size: 14px;
      line-height: 30px;
      font-weight: normal;
  }
  

  .info a {
  
      color: #b3b3b3;
      font-size: 14px;
      line-height: 30px;
      font-weight: normal;
  }
  
  .fa-fax:before {
      content: "\f1ac";
  }
  
  .copyright {
      border-top: 1px solid #111;
      font-size: 14px;
      color: #ccc;
      padding-top: 15px;
      text-align: center;
      padding-bottom: 15px;
      background: #222;
  }
  footer .second_class{
      border-bottom: 1px solid #444;
      padding-bottom: 25px;
  }
  footer .first_class {
      padding-bottom: 21px;
      border-bottom: 1px solid #444;
  }
  footer .first_class p, footer .first_class h3{
      margin: 0 0;
      
  }
  footer{
      background: #ffe4c44d;
  }
  
  footer .newsletter input[type="text"] {
      width: 100%;
      background: #fff;
      color: #333;
      border: 1px solid #222;
      padding: 14px 20px;
      border-radius: 50px;
      margin-top: 12px;
  }
  
  .newsletter .newsletter_submit_btn {
      background: #fff;
      position: absolute;
      right: 30px;
      border: 0;
      top: 26px;
      font-size: 17px;
      color: #333;
  }
  
  
  footer .second_class_bdr{
      padding-top: 25px;
      border-top:1px solid #222;
  }
  
  footer .btn-facebook a {
      padding: 6px 14px !important;
  }
  
  footer .btn-envelop a {
      color: #D6403A;
      font-size: 15px;
      padding: 12px 12px;
  }
  
  footer .round-btn a {
      padding: 5px 10px;
  }
  
  footer .round-btn {
      box-shadow: 2px 2px 5px 0px #222 !important;}
  
  footer .round-btn {
      margin: 15px 4px;}
    
  footer dl, ol, ul {
      padding-left: 5px;
  }
    footer li{
      list-style: none;
    }
  
      
      .subscribe-btn::before {
        content: '';
        position: absolute;
        inset: 0;
        margin: auto;
        width: 60px;
        height: 60px;
        border-radius: inherit;
        scale: 0;
        z-index: -1;
        background-color: #f96c26;
        transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
      }
      
      .subscribe-btn:hover::before {
        scale: 3;
      }
      
      .subscribe-btn:hover {
        color: #fff;
        scale: 1.1;
        box-shadow: 0 0px 20px rgba(193, 163, 98,0.4);
      }
      
      .subscribe-btn:active {
        scale: 1;
      }
      
      
      
  @media(max-width:768px){
      .footer-wrap h3 {
      margin-top: 27px;}
      
      footer .round-btn {
      margin: 15px 4px;}
  }
  @media(max-width:320px){
  .copyright {
      font-size: 13px;}
  }	