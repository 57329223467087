@font-face {
  font-family: 'Montserrat Medium';
  src: url('../fonts/Montserrat-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat Bold';
  src: url('../fonts/Montserrat-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat Regular';
  src: url('../fonts/Montserrat-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Roman';
  src: url('../fonts/helveticaneue-roman.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Bold';
  src: url('../fonts/HelveticaNeueBold.otf');
  font-weight: normal;
  font-style: normal;
}