.listing_inner_data {
  display: flex;
}
.list_main_content-new {
  width: 100%;
  padding-left: 17rem !important;
  background-color: rgba(241, 245, 249, 0.5607843137);
}
.home.product_details {
  margin-top: 7.3rem;
  opacity: 0;
  padding: 20px;
}
.new-home {
  max-height: 100vh;
  overflow: auto;
}
